/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import Vue from 'vue';
import qs from 'qs';
import domain from './domain';
import { localStoragePrefix } from './utils';

const url = domain['ebag-examination']['exam-api-domain'];

const config = {
  'Content-Type': 'application/x-www-form-urlencoded',
};

const getFormData = (params = {}) => {
  const formData = new FormData();
  for (let [k, v] of Object.entries(params)) {
    formData.append(k, v);
  }
  return formData;
}

let lastProgressRequest;
let lastSpotProgressRequest;

const isTestHost = (host) => {
  //oldyang
  //return host.includes('test');
  return false
}

const serve = {
  // 统一访问地址
  url: domain['ebag-examination']['exam-api-domain'],
  answersheetUrl: domain['examination-answer-sheet-api'].domain,
  NEW_PDF_URL: domain['ebag-html2pdf-api'].domain,
  loginUrl: domain['examination-single']['login-domain'],
  downloadPdfUrl: domain['examination-answer-sheet-api'].QiniuDomain,
  // errorUrl: 'http://172.16.1.75',
  errorUrl: domain['examination-single']['exam-api-domain'],
  //获取三年真题token
  getThreeYearsToken(params) {
    return Vue.http({
      url: `${this.url}/v1/zsdata/zhenti/account/token`,
      method: 'get',
      params
    })
  },
  getUploadSheetUrl() {
    return `${this.url}/v1/resource/uploadsheet`;
  },

  getUserType(account) {
    return Vue.http({
      url: `${this.url}/v1/ebag/user`,
      method: 'get',
      params: {
        account,
      },
    });
  },

  // 从服务器获取学段和年级信息
  getStageInfoS() {
    return Vue.http({
      url: `${this.url}/v1/school/system`,
      method: 'get',
      params: {
        school_id: sessionStorage.getItem(`${localStoragePrefix}user_school_id`),
        accesstoken: sessionStorage.getItem(`${localStoragePrefix}session_id`),
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },

  // 从服务器获取班级信息
  getClassInfoS(school_id, grade_id,class_type) {
    return Vue.http({
      url: `${this.url}/v1/school/classes`,
      method: 'get',
      params: {
        school_id,
        grade_id,
        class_type,
        accesstoken: sessionStorage.getItem(`${localStoragePrefix}session_id`),
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },

  // 从服务器获取科目信息
  getSubjectInfoS(school_id, grade_id) {
    return Vue.http({
      url: `${this.url}/v1/school/subjects`,
      method: 'get',
      params: {
        school_id,
        grade_id,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },

  getClassListByGradeId(params) {
    return Vue.http({
      url: `${this.url}/v1/joint/getClassListBySchoolId`,
      method: 'get',
      params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },

  // 保存用户新建的考试信息
  postCreatedInfoS(obj) {
    return Vue.http({
      url: `${this.url}/v1/exams/${sessionStorage.getItem(
        `${localStoragePrefix}user_school_id`,
      )}`,
      method: 'post',
      body: obj,
    });
  },

  // 修改考试信息
  editExamInfo(id, obj) {
    return Vue.http({
      url: `${this.url}/v1/exams/${sessionStorage.getItem(
        `${localStoragePrefix}user_school_id`)}/${id}`,
      method: 'put',
      body: obj,
    });
  },

  // 测试新建考试选取班级是否含有学生
  checkStudentExist(class_list) {
    return Vue.http({
      url: `${this.url}/v1/exams/student/check`,
      method: 'get',
      params: {
        class_list,
      },
    });
  },

  // 保存用户修改的考试信息
  putCreatedInfoS(obj, examId) {
    return Vue.http({
      url: `${this.url}/v1/exams/${examId}`,
      method: 'put',
      body: obj,
    });
  },

  // 从服务器获取全部考试信息列表
  getExamInfoS(
    exam_types,
    isJoint,
    page,
    pageSize,
    searchValue,
    gradeIds,
    gradeId,
    subjectId,
    pickedDate,
    year
  ) {
    let params = {
      school_id: sessionStorage.getItem(`${localStoragePrefix}user_school_id`),
      teacher_id: sessionStorage.getItem(`${localStoragePrefix}user_id`),
      page,
      limit: pageSize,
    };
    if (exam_types) {
      params = {
        ...params,
        exam_types,
      };
    }
    if (searchValue) {
      params = {
        ...params,
        search: searchValue,
      };
    }
    if (gradeIds) {
      params = {
        ...params,
        grade_ids: gradeIds,
      };
    } else {
      params = {
        ...params,
        grade_id: gradeId,
      };
    }
    if (subjectId) {
      params = {
        ...params,
        subject_id: subjectId,
      };
    }
    if (isJoint) {
      params.is_joint = 1;
    }
    if (pickedDate) {
      params.time_select = pickedDate;
    }
    if (year) {
      params.year = year
    }
    return Vue.http({
      url: `${this.url}/v1/exams/newlist/${sessionStorage.getItem(
        `${localStoragePrefix}user_school_id`,
      )}`,
      method: 'get',
      params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },

  // 获取单场考试信息
  getSingleExamS(examId) {
    return Vue.http({
      url: `${this.url}/v1/exams/${sessionStorage.getItem(
        `${localStoragePrefix}user_school_id`,
      )}/${examId}`,
      method: 'get',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  getCompareExams(examId) {
    return Vue.http({
      url: `${this.url}/v1/exams/newlist4/${examId}`,
      method: 'get',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  //复制考试
  copyExam(examObj) {
    return Vue.http({
      url: `${this.url}/v1/exams/copy`,
      method: 'post',
      body: examObj,
    });
  },
  // 删除当前考试
  deleteSelfS(deleteId) {
    return Vue.http({
      url: `${this.url}/v1/exams/${sessionStorage.getItem(
        `${localStoragePrefix}user_school_id`,
      )}/${deleteId}`,
      method: 'delete',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  // 获取题目结构
  getSheetInfo(sheet_id) {
    return Vue.http({
      url: `${this.answersheetUrl}/v1/transfer/answersheet`,
      method: 'get',
      params: sheet_id,
    });
  },
  // 智能导题获取答题卡信息
  getImportQuestionInfo(section_id) {
    return Vue.http({
      url: `${this.answersheetUrl
        }/v2/web/homework/sheet/${section_id}/questions`,
      method: 'get',
    });
  },
  // 发送题卡绑定结果
  bindQuestions(question_ids, box_ids, entry, transfer_id, sheet_id) {
    return Vue.http({
      url: `${this.answersheetUrl}/v2/web/boxes/bindquestions`,
      method: 'post',
      body: qs.stringify({
        question_ids: JSON.stringify(question_ids),
        box_ids: JSON.stringify(box_ids),
        from: JSON.stringify(entry),
        virtual_sheet_id: JSON.stringify(transfer_id),
        sheet_id: JSON.stringify(sheet_id),
      }),
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
  },

  getAnswerSheet(obj) {
    return Vue.http({
      url: `${this.url}/v1/answersheet/list2`,
      method: 'get',
      params: obj,
    });
  },

  // 保存用户在线引用的一张答题卡
  saveSingleSheetS({
    F_teacher_id,
    F_exam_id,
    F_subject_id,
    F_resource_id,
    F_resource_name,
    F_full_score,
    F_paper_type,
    F_sheet_count,
    F_exam_mode,
    F_save_type,
    F_sheet_url_type,
    F_anchor_type,
    F_sheet_url
  }) {
    return Vue.http({
      url: `${this.url}/v1/answersheet/import2`,
      method: 'post',
      params: {
        F_teacher_id,
        F_exam_id,
        F_subject_id,
        F_resource_id,
        F_resource_name,
        F_full_score,
        F_paper_type,
        F_sheet_count,
        F_exam_mode,
        F_save_type,
        F_sheet_url_type,
        F_anchor_type,
        F_sheet_url
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  // 获取单张答题卡详细信息(废弃)
  getSingleSheetS(sheetId, examId, subjectId) {
    return Vue.http({
      url: `${this.url}/v1/answersheet/get`,
      method: 'get',
      params: {
        F_resource_id: sheetId,
        F_exam_id: examId,
        F_subject_id: subjectId,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  // 获取全部老师列表
  getAllTeacherS(exam_id, subject_id) {
    return Vue.http({
      url: `${this.url}/v1/school/markers`,
      method: 'get',
      params: {
        exam_id,
        subject_id,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },

  // 批量获取老师信息使用MarkID来获取对应的姓名
  getAllTeachersName(marker_ids) {
    return Vue.http({
      url: `${this.url}/v1/school/markers/list`,
      method: 'get',
      params: {
        marker_ids,
      },
    });
  },

  // 获取曾经设置过的科组长（不用）
  getHistoryLeaderS(examId, subjectId) {
    return Vue.http({
      url: `${this.url}/v1/exams/${examId}/${subjectId}/marker_admins`,
      method: 'get',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },

  // 获取曾经设置过的阅卷员（不用）
  getHistoryMarkerS(examId, subjectId) {
    return Vue.http({
      url: `${this.url}/v1/exams/${examId}/${subjectId}/markers`,
      method: 'get',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },

  // 获取曾经设置过的阅卷员分工（不用）
  getDivisionS(examId, subjectId) {
    return Vue.http({
      url: `${this.url}/v1/exams/sheetmarkers/get`,
      method: 'get',
      params: {
        exam_id: examId,
        subject_id: subjectId,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },

  // 获取已经分配好的阅卷员
  getMarkers(exam_id, subject_id) {
    return Vue.http({
      url: `${this.url}/v1/marker/set/get`,
      method: 'get',
      params: {
        exam_id,
        subject_id,
      },
    });
  },

  // 设置阅卷员分工
  setMarkers(exam_id, subject_id, markerData) {
    return Vue.http({
      url: `${this.url}/v1/marker/set`,
      method: 'post',
      params: {
        exam_id,
        subject_id,
      },
      body: markerData,
    });
  },

  // 二次修改阅卷分工情况
  resetMarkers(exam_id, subject_id, markerData) {
    return Vue.http({
      url: `${this.url}/v1/marker/reset`,
      method: 'post',
      params: {
        exam_id,
        subject_id,
      },
      body: markerData,
    });
  },

  // 在线解析班级内容
  analyzeClassS(class_ids) {
    return Vue.http({
      url: `${this.url}/v1/school/classes/list`,
      method: 'post',
      params: {
        accesstoken: sessionStorage.getItem(`${localStoragePrefix}session_id`),
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: class_ids,
    });
  },

  // 在线解析各种阅卷员(不用)
  analyzeMarkerS(markerIds) {
    return Vue.http({
      url: `${this.url}/v1/school/markers/list`,
      method: 'get',
      params: {
        marker_ids: markerIds,
        accesstoken: sessionStorage.getItem(`${localStoragePrefix}session_id`),
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },

  // 保存选中的阅卷员
  saveMarkersS(examId, subjectId, markersId) {
    return Vue.http({
      url: `${this.url}/v1/exams/${examId}/${subjectId}/markers`,
      method: 'put',
      body: markersId,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  // 保存选中组长
  saveLeadersS(examId, subjectId, leadersId) {
    return Vue.http({
      url: `${this.url}/v1/exams/${examId}/${subjectId}/marker_admins`,
      method: 'put',
      body: leadersId,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  // 保存阅卷员分工
  saveDivisionS(examId, subjectId, classId, division) {
    return Vue.http({
      url: `${this.url}/v1/exams/sheetmarkers/save`,
      method: 'put',
      params: {
        exam_id: examId,
        subject_id: subjectId,
        class_id: classId,
      },
      body: division,
    });
  },

  // 保存用户对题型的正确答案以及满分设置
  saveScoreSet(formData) {
    return Vue.http({
      url: `${this.url}/v1/answersheet/save2`,
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      body: formData,
    });
  },

  //主观题拆分小题
  splitQuestion(formData) {
    return Vue.http({
      url: `${this.url}/v1/answersheet/splitquestion`,
      method: 'post',
      params: formData,
    });
  },

  // 获取阅卷任务列表
  getTaskListS(markerId) {
    return Vue.http({
      url: `${this.url}/v1/marker/get`,
      method: 'get',
      params: {
        marker_id: markerId,
      },
    });
  },

  // 获取单场考试的阅卷任务
  getSingleTaskListS(marker_id, exam_id, subject_id, is_question_master) {
    return Vue.http({
      url: `${this.url}/v1/marker/get`,
      method: 'get',
      params: {
        marker_id,
        exam_id,
        subject_id,
        is_question_master,
      },
    });
  },

  // 获取某英语老师的10个图片的特定接口
  getSpecailTaskListS(marker_id, exam_id, subject_id) {
    return Vue.http({
      url: `${this.url}/v1/marker/specail`,
      method: 'get',
      params: {
        marker_id,
        exam_id,
        subject_id,
      },
    });
  },

  // 获取对应题号的详情作答情况
  getSingleQuestion(param) {
    return Vue.http({
      url: `${this.url}/v1/marker/single`,
      method: 'get',
      params: param,
    });
  },

  // 获取对应id的详情作答情况
  getSingleQuestionDetails(id) {
    return Vue.http({
      url: `${this.url}/v1/marker/single/detail`,
      method: 'get',
      params: {
        id,
      },
    });
  },

  // 下载老师阅卷图片压缩包
  getPaperImages(params) {
    return Vue.http({
      url: `${this.url}/v1/result/paper/typical/images`,
      method: 'get',
      params,
      responseType: 'blob',
    });
  },

  setTaskScore({
    id,
    result_id,
    tag_type,
    block_data,
    tag_desc,
    is_comment,
    comment_content,
    comment_pic,
    is_reject,
    reject_marker_id,
  }) {
    const body = new FormData();
    body.append('id', id);
    body.append('result_id', result_id);
    body.append('tag_type', tag_type);
    body.append('block_data', block_data);

    if (tag_desc) {
      body.append('tag_desc', tag_desc);
    }

    if (typeof is_comment === 'number') {
      body.append('is_comment', is_comment);
      body.append('comment_content', comment_content);
      body.append('comment_pic', comment_pic);
    }

    if (is_reject === 1) {
      body.append('is_reject', is_reject);
      body.append('reject_marker_id', reject_marker_id);
    }

    return Vue.http({
      url: `${this.url}/v1/marker/markedcorrect`,
      method: 'post',
      body,
    });
  },

  // 获取一场考试阅卷的详细任务信息
  setTopicListsS(obj) {
    return Vue.http({
      url: `${this.url}/v1/question/get`,
      method: 'get',
      params: obj,
    });
  },

  // 根据班级id获取对应的学生信息
  getStudentsS(classId) {
    return Vue.http({
      url: `${this.url}/v1/teaching/students`,
      method: 'get',
      params: {
        class_id: classId,
      },
    });
  },

  getAbnormalS(obj) {
    return Vue.http({
      url: `${this.url}/v1/checking/get`,
      method: 'get',
      params: obj,
    });
  },

  // 异常核查-修改分数
  scoreAbnormalS(data) {
    return Vue.http({
      url: `${this.url}/v1/checking/score`,
      method: 'post',
      body: data,
    });
  },

  // 请求智能阅卷
  goSmartMarkS(obj) {
    return Vue.http({
      url: `${this.url}/v1/resource/marking`,
      method: 'post',
      params: obj,
    });
  },

  // 获取阅卷状态
  getSmartMarkS(obj, classIds) {
    return Vue.http({
      url: `${this.url}/v1/resource/marking`,
      method: 'get',
      params: {
        exam_id: obj.exam_id,
        subject_id: obj.subject_id,
        class_ids: classIds,
      },
    });
  },

  // 处理异常问题卷
  doException(obj) {
    return Vue.http({
      url: `${this.url}/v1/checking/exception/do`,
      method: 'post',
      params: obj,
    });
  },

  isHaveException(obj) {
    return Vue.http({
      url: `${this.url}/v1/checking/exception/have`,
      method: 'get',
      params: obj,
    });
  },

  // 获取智能阅卷结果(新)
  getSmartResultS(obj) {
    return Vue.http({
      url: `${this.url}/v1/result/info`,
      method: 'get',
      params: obj,
    });
  },

  // 获取智能阅卷结果(整个年级)
  getSmartResultTotal(obj) {
    return Vue.http({
      url: `${this.url}/v1/result/total`,
      method: 'get',
      params: obj,
    });
  },

  // 获取纪雅中学考试结果（测试用）
  getJiyaExamResult(obj) {
    return Vue.http({
      url: `${this.url}/v1/result/jiya`,
      method: 'get',
      params: obj,
    });
  },

  // 主观题批改分数
  sendCorrect(obj, jsonData) {
    return Vue.http({
      url: `${this.url}/v1/marker/correct`,
      method: 'post',
      params: obj,
      body: jsonData,
    });
  },

  // 获取批改中的异常卷信息
  // exam_id     number   考试id
  // subject_id  number   科目id
  getExceptionResults(obj) {
    return Vue.http({
      url: `${this.url}/v1/checking/exception2`,
      method: 'get',
      params: obj,
    });
  },

  // 异常核查修复(废弃)
  fixAbnormal(obj) {
    return Vue.http({
      url: `${this.url}/v1/checking/fix`,
      method: 'post',
      params: obj,
    });
  },

  // 更改单题分数
  // exam_id     number   考试id
  // subject_id  number   科目id
  // class_id    string   班级id
  // student_no  string   学生id
  // index       number   题目序号索引(0起始)
  // score       number   修改分数(分数)
  fixSingleScore(obj) {
    return Vue.http({
      url: `${this.url}/v1/checking/fix`,
      method: 'post',
      params: obj,
    });
  },

  // 标记学生是否缺考
  // exam_id     number   考试id
  // subject_id  number   科目id
  // class_id    string   班级id
  // student_nos string   学生id，例[123456,223456]
  signMissingStudent(params) {
    return Vue.http({
      url: `${this.url}/v1/resource/sign?exam_id=${params.exam_id}&subject_id=${params.subject_id
        }`,
      method: 'post',
      body: params.data,
    });
  },

  // 获取学生缺考状态
  // exam_id     number   考试id
  // subject_id  number   科目id
  // class_id    string   班级id
  getMissingStudent(obj) {
    return Vue.http({
      url: `${this.url}/v1/resource/missing`,
      method: 'get',
      params: obj,
    });
  },

  // 获取阅卷进度
  getSchedule(obj) {
    return Vue.http({
      url: `${this.url}/v1/marker/finish`,
      method: 'get',
      params: obj,
    });
  },

  // 更改学生考试状态
  postStatus(obj) {
    return Vue.http({
      url: `${this.url}/v1/result/update`,
      method: 'post',
      params: obj,
    });
  },

  /**
   * 确定结束一场考试
   * @param {number} exam_id - 考试id
   * @param {number} subject_id - 科目id
   * @param {string} marker_id - 阅卷员id
   * @param {number} is_admin - 是否是管理员
   */
  finishMark(exam_id, subject_id, marker_id, is_admin) {
    return Vue.http({
      url: `${this.url}/v1/marker/confirm`,
      method: 'post',
      params: {
        exam_id,
        subject_id,
        marker_id,
        is_admin,
      },
    });
  },

  // 更新一场考试的进度
  updateStatus(exam_id, subject_id) {
    return Vue.http({
      url: `${this.url}/v1/status/update`,
      method: 'post',
      params: {
        exam_id,
        subject_id,
      },
    });
  },

  // 更新一场考试的某一科目阅卷设置状态
  updateMarkset(examId, subjectId) {
    return Vue.http({
      url: `${this.url}/v1/exams/sheetmarkers/set`,
      method: 'post',
      params: {
        exam_id: examId,
        subject_id: subjectId,
      },
    });
  },

  // 获取某场考试的全部上传进度
  getUploadProgress(examId) {
    return Vue.http({
      url: `${this.url}/v1/status/upload/get`,
      method: 'get',
      params: {
        exam_id: examId,
      },
    });
  },

  // 获取某场考试的全部上传进度
  getMarkersInfo(exam_id, subject_id, page, limit, block_id, is_admin) {
    let param = {
      exam_id,
      subject_id,
      page,
      limit,
      is_admin,
    };
    if (block_id) {
      param = {
        ...param,
        block_id,
      };
    }
    return Vue.http({
      url: `${this.url}/v1/marker/status`,
      method: 'get',
      params: param,
    });
  },

  // 针对阅卷监管获取考试信息
  getRegulationPaperInfo(
    exam_id,
    subject_id,
    page,
    limit,
    marker_id,
    block_id,
    province_id,
    city_id,
    district_id,
    school_id,
    search,
    type_index,
    currentRoute,
    showHeaderInfo,
    is_not_mark,
    fromPGYJ = false
  ) {
    let param = {
      exam_id,
      subject_id,
      page,
      limit,
      marker_id,
      block_id,
      province_id,
      city_id,
      district_id,
      school_id,
      search,
      type_index,
      is_not_mark,
    };
    if (
      currentRoute.name === 'evaluationManage'
      || currentRoute.path === '/MarkingSupervision'
      || !showHeaderInfo
    ) {
      param = {
        ...param,
        is_admin: 1,
      };
    }
    const arr = Object.keys(param);
    arr.forEach((r) => {
      if (!param[r] && r !== 'type_index') {
        delete param[r];
      }
    });
    // 批改阅卷的界面 获取表格参数不传city_id和marker_id
    if (fromPGYJ) {
      delete param['marker_id']
      delete param['city_id']
    }
    return Vue.http({
      url: `${this.url}/v1/marker/spot/check/list`,
      method: 'get',
      params: param,
    });
  },

  // 获取某场考试的缺考情况
  getMissing(examInfo) {
    return Vue.http({
      url: `${this.url}/v1/result/missing`,
      method: 'get',
      params: examInfo,
    });
  },

  // 获取某场考试（暂无科目）的状态
  getStatus(exam_id, subject_id) {
    return Vue.http({
      url: `${this.url}/v1/exams/status`,
      method: 'get',
      params: {
        exam_id,
        subject_id,
      },
    });
  },

  /** 查看一场考试是否可以查看成绩报表（是否已发布成绩） */
  judgeSnapshot(params) {
    return Vue.http({
      url: `${this.url}/v1/result/judge/snapshot`,
      method: 'get',
      params,
    });
  },

  // 获取某场阅卷的记录情况
  getMarkingMq(id) {
    return Vue.http({
      url: `${this.url}/v1/resource/mq`,
      method: 'get',
      params: {
        id,
      },
    });
  },

  // 匹配异常卷的处理
  matchExceptionInfo(obj) {
    return Vue.http({
      url: `${this.url}/v1/checking/match`,
      method: 'post',
      params: obj,
    });
  },

  // 阅卷相关测试接口
  test(obj) {
    return Vue.http({
      url: `${this.url}/v1/test/test`,
      method: 'get',
      params: obj,
    });
  },

  // 七牛上传接口
  getQiniuToken() {
    return Vue.http({
      url: `${this.url}/v1/qiniu/token`,
      method: 'get',
    });
  },

  // 桌面端阅卷使用接口
  getDesktopMaring() {
    return Vue.http({
      url: `${this.url}/v1/desktop/marking`,
      method: 'post',
    });
  },

  // 获取当前考试的班级列表
  getExamClassList(exam_id) {
    return Vue.http({
      url: `${this.url}/v1/school/student/classes`,
      method: 'get',
      params: {
        exam_id,
      },
    });
  },

  // 获取当前考试的班级列表
  getExamStudentInfos(class_ids) {
    return Vue.http({
      url: `${this.url}/v1/school/class/students`,
      method: 'get',
      params: {
        class_ids,
      },
    });
  },

  getClassesInfo(params) {
    return Vue.http({
      url: `${this.url}/v1/classes/info`,
      method: 'get',
      params,
    });
  },

  // 上传学生信息文件
  uploadStudentFile(data) {
    return Vue.http({
      url: `${this.url}/v1/school/student/translate`,
      method: 'post',
      body: data,
    });
  },

  // 获取学生列表
  getExamStudentList(exam_id, class_id) {
    return Vue.http({
      url: `${this.url}/v1/school/student/list`,
      method: 'get',
      params: {
        exam_id,
        class_id,
      },
    });
  },

  // 姓名（非必填）
  // 学号（必填）
  // 考号（必填）
  // 年级（非必填）
  // 班级（非必填）
  // 考场（非必填）
  // 座位号（非必填）
  addExamStudent(student) {
    return Vue.http({
      url: `${this.url}/v1/school/student/add`,
      method: 'post',
      body: student,
    });
  },

  updateExamStudent(student) {
    return Vue.http({
      url: `${this.url}/v1/school/student/update`,
      method: 'post',
      body: student,
    });
  },

  deleteExamStudent(ids) {
    return Vue.http({
      url: `${this.url}/v1/school/student/delete`,
      method: 'post',
      body: ids,
    });
  },

  // 创建个人版班级
  setPersonalClass(name) {
    return Vue.http({
      url: `${this.url}/v1/personal/class/create`,
      method: 'post',
      params: {
        user_id: 1111,
        name,
      },
    });
  },

  // 更新个人版班级
  updatePersonalClass(class_id, name) {
    return Vue.http({
      url: `${this.url}/v1/personal/class/update`,
      method: 'post',
      params: {
        class_id,
        name,
      },
    });
  },

  // 删除个人版班级
  deletePersonalClass(class_id) {
    return Vue.http({
      url: `${this.url}/v1/personal/class/delete`,
      method: 'post',
      params: {
        class_id,
      },
    });
  },

  // 获取个人版学生列表
  getPersonalStudentList(class_id) {
    return Vue.http({
      url: `${this.url}/v1/personal/student/list`,
      method: 'get',
      params: {
        class_id,
      },
    });
  },

  // 创建个人版学生信息
  setPersonalStudent(class_id, data) {
    return Vue.http({
      url: `${this.url}/v1/personal/student/create`,
      method: 'post',
      params: {
        class_id,
      },
      body: data,
    });
  },

  // 更新个人版学生信息
  updatePersonalStudent(student_no, name, number) {
    return Vue.http({
      url: `${this.url}/v1/personal/student/update`,
      method: 'post',
      params: {
        student_no,
        name,
        number,
      },
    });
  },

  // 删除个人版学生信息
  deletePersonalStudent(student_no) {
    return Vue.http({
      url: `${this.url}/v1/personal/student/delete`,
      method: 'post',
      params: {
        student_no,
      },
    });
  },

  // 批量检测考号是否被使用
  checkStudentIdUse(data) {
    return Vue.http({
      url: `${this.url}/v1/personal/student/check`,
      method: 'post',
      body: data,
    });
  },

  // 检查阅卷份数进度
  checkInfoUpdate(exam_id, subject_id) {
    return Vue.http({
      url: `${this.url}/v1/result/actual`,
      method: 'get',
      params: {
        exam_id,
        subject_id,
      },
    });
  },

  // id :  result的id
  // new_class_id 新班级id
  // new_student_no 新准考证号
  // 匹配准考证号
  numberSinglematch({ id, new_class_id, new_student_no }) {
    console.log(id, new_class_id, new_student_no);
    return Vue.http({
      url: `${this.url}/v1/checking/singlematch`,
      method: 'post',
      params: {
        id,
        new_class_id,
        new_student_no,
      },
    });
  },

  // 桌面端的网页识别核查进行重新上传
  checkOmrReUpload(id) {
    return Vue.http({
      url: `${this.url}/v1/checking/omr`,
      method: 'post',
      params: {
        id,
      },
    });
  },

  // 获取本场考试是否录了题目
  existQuestions(F_exam_id, F_subject_id) {
    return Vue.http({
      url: `${this.url}/v1/answersheet/srcquestion/exist`,
      method: 'get',
      params: {
        F_exam_id,
        F_subject_id,
      },
    });
  },

  // 获取本场考试的考试题目
  getTestPaper(F_exam_id, F_subject_id) {
    return Vue.http({
      url: `${this.url}/v1/answersheet/detail2`,
      method: 'get',
      params: {
        F_exam_id,
        F_subject_id,
      },
    });
  },

  // 重置批改记录
  resetMarking(data) {
    return Vue.http({
      url: `${this.url}/v1/result/reset/task/detail`,
      method: 'post',
      body: data,
    });
  },

  changeOptions(data) {
    return Vue.http({
      url: `${this.url}/v1/result/fix/option`,
      method: 'post',
      body: data,
    });
  },

  // 获取成绩报告考试题目的信息
  getSourceQuestionInfo(F_exam_id, F_subject_id) {
    return Vue.http({
      url: `${this.url}/v1/answersheet/sourcequestion`,
      method: 'get',
      params: {
        F_exam_id,
        F_subject_id,
      },
    });
  },

  // 获取阅卷任务考试题目的信息
  getBlockQuestionInfo(F_exam_id, F_subject_id) {
    return Vue.http({
      url: `${this.url}/v1/answersheet/block/sourcequestion`,
      method: 'get',
      params: {
        F_exam_id,
        F_subject_id,
      },
    });
  },

  // 获取阅卷桌面端进度数据(已不用)
  getDesktopMarkingr(exam_id, subject_id, mode, url_paths) {
    return Vue.http({
      url: `${this.url}/v1/desktop/marking`,
      method: 'post',
      params: {
        exam_id,
        subject_id,
        mode,
        url_paths,
      },
    });
  },

  // 获取一场考试所有学生的考试成绩列表
  getExamScoreResult(exam_id) {
    return Vue.http({
      url: `${this.url}/v1/result/common`,
      method: 'get',
      params: {
        exam_id,
      },
    });
  },

  // 获取学校学生信息（为了依据学生学号去匹配学生姓名）
  getSchoolStudentInfo(school_id) {
    return Vue.http({
      url: `${this.url}/v1/school/students`,
      method: 'get',
      params: {
        school_id,
      },
    });
  },

  // 获取知识点列表
  getKnowledgeList(stage_id, subject_id) {
    return Vue.http({
      url: `${this.url}/v1/zsdata/keypoint`,
      method: 'get',
      params: {
        stage_id,
        subject_id,
      },
    });
  },

  // 提交补充的知识点
  addKnowledgeList(F_knowledge_info) {
    return Vue.http({
      url: `${this.url}/v1/answersheet/knowledge`,
      method: 'post',
      body: F_knowledge_info,
    });
  },

  // 获取班级试卷分析结果
  getAnalyseResult(exam_id, subject_id, class_ids) {
    return Vue.http({
      url: `${this.url}/v1/report/class/paper`,
      method: 'get',
      params: {
        exam_id,
        subject_id,
        class_ids,
      },
    });
  },

  // 获取筛选条件
  getFilterCondition() {
    return Vue.http({
      url: `${this.url}/v1/exams/condition`,
      method: 'get',
    });
  },

  // 获取班级报告中的筛选结果
  // school_id,page,limit,exam_type,time_type,sem_type, grade,subject
  // school_id必传
  // page, limit暂时不传，以后分页用
  getFilterResult({
    school_id,
    exam_type,
    time_type,
    sem_type,
    grade,
    subject,
  }) {
    return Vue.http({
      url: `${this.url}/v1/report/class/list`,
      method: 'get',
      params: {
        school_id,
        exam_type,
        time_type,
        sem_type,
        grade,
        subject,
        teacher_id: sessionStorage.getItem(`${localStoragePrefix}user_id`),
      },
    });
  },

  // 校级报告的筛选接口（去除了学科选择）
  getGradeFilterResult({
    school_id, exam_type, time_type, sem_type, grade,
  }) {
    return Vue.http({
      url: `${this.url}/v1/report/grade/list`,
      method: 'get',
      params: {
        school_id,
        exam_type,
        time_type,
        sem_type,
        grade,
        teacher_id: sessionStorage.getItem(`${localStoragePrefix}user_id`),
      },
    });
  },

  // 获取班级学期总览列表
  getPaperReviewResult(exam_id, subject_id, class_id) {
    return Vue.http({
      url: `${this.url}/v1/report/class/comment`,
      method: 'get',
      params: {
        exam_id,
        subject_id,
        class_id,
      },
    });
  },

  // 获取班级成绩单列表
  getScoreSheetResult(exam_id, subject_id, class_id) {
    return Vue.http({
      url: `${this.url}/v1/report/class/single`,
      method: 'get',
      params: {
        exam_id,
        subject_id,
        class_id,
        school_id: sessionStorage.getItem(`${localStoragePrefix}user_school_id`),
      },
    });
  },

  // 获取班级学期总览列表
  getTermResult(exam_id, subject_id, class_id) {
    return Vue.http({
      url: `${this.url}/v1/report/class/learning`,
      method: 'get',
      params: {
        exam_id,
        subject_id,
        class_id,
        school_id: sessionStorage.getItem(`${localStoragePrefix}user_school_id`),
      },
    });
  },

  // 获取校级学生成绩单列表
  getGradeScoreSheetResult(exam_id, subject_ids, class_ids) {
    return Vue.http({
      url: `${this.url}/v1/report/grade/single`,
      method: 'get',
      params: {
        exam_id,
        subject_ids,
        class_ids,
        school_id: sessionStorage.getItem(`${localStoragePrefix}user_school_id`),
      },
    });
  },

  // 获取校级成绩分析结果
  getGradeAnalyseReport(exam_id, subject_ids) {
    return Vue.http({
      url: `${this.url}/v1/report/grade/analyse`,
      method: 'get',
      params: {
        exam_id,
        subject_ids,
        school_id: sessionStorage.getItem(`${localStoragePrefix}user_school_id`),
      },
    });
  },

  //   /v1/supervision/detail
  // school_id
  // time_type
  // grade
  // page
  // limit
  // 获取教学监管的筛选数据
  getSupervisionDetail(school_id, time_type, grade) {
    return Vue.http({
      url: `${this.url}/v1/supervision/detail`,
      method: 'get',
      params: {
        school_id,
        time_type,
        grade,
      },
    });
  },

  // 获取首页教师信息
  /**
   * @param {String} school_id
   * @param {String} teacher_id
   */
  getHomePageInfo(school_id, teacher_id) {
    return Vue.http({
      url: `${this.url}/v1/homepage/get`,
      method: 'get',
      params: {
        school_id,
        teacher_id,
      },
    });
  },

  // /v1/school/system: 获取学校阶段年级信息
  /**
   * @param {String} school_id
   * @param {String} accesstoken
   */
  getSchoolGradeInfo(school_id, accesstoken) {
    return Vue.http({
      url: `${this.url}/v1/school/system`,
      method: 'get',
      params: {
        school_id,
        accesstoken,
      },
    });
  },
  /**
   * @param {Number} school_id
   * @param {Number} grade_id
   * @param {String} accesstoken
   */
  getSchoolClassInfo(school_id, grade_id, accesstoken) {
    return Vue.http({
      url: `${this.url}/v1/school/classes`,
      method: 'get',
      params: {
        school_id,
        grade_id,
        accesstoken,
      },
    });
  },

  /**
   * @param {Number} class_id
   * @param {String} accesstoken
   */
  getClassInfo(class_id, accesstoken) {
    return Vue.http({
      url: `${this.url}/v1/class/info`,
      method: 'get',
      params: {
        class_id,
        accesstoken,
      },
    });
  },

  /**
   * 是否必须 Y/N
   * @param {String} school_id  Y
   * @param {Number} role :角色id(1:校长，2:管理员，3:班主任，4:科组长，5:教师)  Y
   * @param {Number} grade_id (当role === 3  时 为必须) N
   * @param {Number} subject_id  N
   * @param {String} accesstoken Y
   */
  getRoleList(roleObj) {
    const {
      school_id, role, grade_id, subject_id,
    } = roleObj;
    return Vue.http({
      url: `${this.url}/v1/school/user`,
      method: 'get',
      params: {
        school_id,
        role,
        grade_id,
        subject_id,
        accesstoken: sessionStorage.getItem(`${localStoragePrefix}session_id`),
      },
    });
  },
  /**
   *
   */
  getSearchList(keyword) {
    return Vue.http({
      url: `${this.url}/v1/school/user/search`,
      method: 'get',
      params: {
        school_id: sessionStorage.getItem(`${localStoragePrefix}user_school_id`),
        keyword,
        accesstoken: sessionStorage.getItem(`${localStoragePrefix}session_id`),
      },
    });
  },
  /**
   * 设置角色
   */
  setRole(obj) {
    return Vue.http({
      url: `${this.url}/v1/school/user/role`,
      method: 'post',
      params: obj,
    });
  },

  // 获取权限列表
  getRoleAuthority() {
    return Vue.http({
      url: `${this.url}/v1/school/user/authority`,
      method: 'get',
      params: {
        user_id: sessionStorage.getItem(`${localStoragePrefix}user_id`),
        accesstoken: 'CFIsGgvkonYEoVURomNZCk1HwshSQhDw',
      },
    });
  },
  // 获取学校名称
  getSchoolName(school_id) {
    return Vue.http({
      url: `${this.url}/v1/school/name`,
      method: 'get',
      params: {
        school_id,
      },
    });
  },
  // 获取一场考试的学生人数
  getExamStudentNumber(exam_id) {
    return Vue.http({
      url: `${this.url}/v1/exams/student/number`,
      method: 'get',
      params: {
        exam_id,
      },
    });
  },

  // 获取时间范围（学科学情）
  getTimeList() {
    return Vue.http({
      url: `${this.url}/v1/academic/subjectSituation/timeDuation`,
      method: 'get',
      params: {
        accesstoken: 'CFIsGgvkonYEoVURomNZCk1HwshSQhDw',
      },
    });
  },

  // 获取薄弱知识点
  getWeakKnowledges(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/subjectSituation/weakKnowledges`,
      method: 'get',
      params: obj,
    });
  },

  // 学情分析:获取时间范围内考试错题概要信息
  getWrongQuestionAbstract(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/subjectSituation/wrongQuestionAbstract`,
      method: 'get',
      params: obj,
    });
  },

  // 学情分析:获取班级共性错题
  getCommonWrongQuestion(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/subjectSituation/commonWrongQuestion`,
      method: 'get',
      params: obj,
    });
  },

  // 学情分析:获取时间范围内的考试
  getExams(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/subjectSituation/exams`,
      method: 'get',
      params: obj,
    });
  },

  // 根据知识点每个学生的掌握率
  getKnowledgeStudentInfo(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/subjectSituation/knowledge/student`,
      method: 'get',
      params: obj,
    });
  },
  // 每个知识点每场考试的掌握率
  getKnowledgeExamInfo(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/subjectSituation/knowledge/exam`,
      method: 'get',
      params: obj,
    });
  },
  // 学情分析:根据知识点的错题信息

  getKnowledgeTopic(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/subjectSituation/knowledge/question`,
      method: 'get',
      params: obj,
    });
  },

  // 学情分析： 获取学校年级、班级和科目
  getGradeInfo() {
    return Vue.http({
      url: `${this.url}/v1/school/classsubject`,
      method: 'get',
      params: {
        teacher_id: sessionStorage.getItem(`${localStoragePrefix}user_id`),
        school_id: sessionStorage.getItem(`${localStoragePrefix}user_school_id`),
        accesstoken: sessionStorage.getItem(`${localStoragePrefix}session_id`),
      },
    });
  },

  // 学情分析:每个学生每个知识点的掌握率
  getStudentLiteracy(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/studentSituation/studentLiteracy`,
      method: 'get',
      params: obj,
    });
  },
  setStudentMarkStatus(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/studentSituation/markStudent`,
      method: 'get',
      params: obj,
    });
  },

  // 学情分析: 获取学年列表
  getTimeTypeList() {
    return Vue.http({
      url: `${this.url}/v1/academic/timetype`,
      method: 'get',
      params: {
        school_id: sessionStorage.getItem(`${localStoragePrefix}user_school_id`),
        accesstoken: sessionStorage.getItem(`${localStoragePrefix}session_id`),
      },
    });
  },

  // 学情分析:单个学生的知识点情况
  getStudentKnowledge(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/studentSituation/student/knowledge`,
      method: 'get',
      params: obj,
    });
  },

  // 学情分析:单个学生的错题情况
  getStudentQuestion(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/studentSituation/student/question`,
      method: 'post',
      body: qs.stringify(obj),
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
  },

  //
  getStudentExam(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/studentSituation/student/exam`,
      method: 'get',
      params: obj,
    });
  },

  // 在线考试查看是否存在补交编码  /v1/pad/exam/makeup（get) 传exam_id 和 subject_id
  judgeResubmitCode(exam_id, subject_id) {
    return Vue.http({
      url: `${this.url}/v1/pad/exam/makeup`,
      method: 'get',
      params: {
        exam_id,
        subject_id,
      },
    });
  },

  // 在线考试创建补交编码  /v1/pad/exam/makeup(post) 传exam_id 和 subject_id
  creatResubmitCode(exam_id, subject_id) {
    return Vue.http({
      url: `${this.url}/v1/pad/exam/makeup`,
      method: 'post',
      params: {
        exam_id,
        subject_id,
      },
    });
  },

  // 获取对应在线考试信息/v1/pad/exam/status   传exam_id ,subject_id ,school_id
  getOnlineTestInfo(exam_id, subject_id, school_id) {
    return Vue.http({
      url: `${this.url}/v1/pad/exam/status`,
      method: 'get',
      params: {
        exam_id,
        subject_id,
        school_id,
      },
    });
  },

  // 获取开启了is_infulse云处理的考试实时扫描信息
  getinfuseTestInfo(exam_id, subject_id, school_id) {
    return Vue.http({
      url: `${this.url}/v1/infuse/status`,
      method: 'get',
      params: {
        exam_id,
        subject_id,
        school_id,
      },
    });
  },

  // 结束云处理考试的扫描监控
  infuseScanComplete(exam_id, subject_id) {
    return Vue.http({
      url: `${this.url}/v1/infuse/complete`,
      method: 'post',
      params: {
        exam_id,
        subject_id,
      },
    });
  },

  // 在线考试的发布 /v1/pad/exam/release
  onlineTestRelease(exam_id) {
    return Vue.http({
      url: `${this.url}/v1/pad/exam/release`,
      method: 'post',
      params: {
        exam_id,
      },
    });
  },

  // 在线考试的取消发布  /v1/pad/exam/revoke
  onlineTestRevoke(exam_id) {
    return Vue.http({
      url: `${this.url}/v1/pad/exam/revoke`,
      method: 'post',
      params: {
        exam_id,
      },
    });
  },

  // 结束某一科目的在线考试
  onlineTestComplete(exam_id, subject_id) {
    return Vue.http({
      url: `${this.url}/v1/pad/exam/complete`,
      method: 'post',
      params: {
        exam_id,
        subject_id,
      },
    });
  },

  // 学生学情 获取学生列表
  getStudentSituationStudentList(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/studentSituation/students`,
      method: 'get',
      params: obj,
    });
  },
  // 学生学情 学业整体水平
  getAcademicOverall(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/studentSituation/academicOverall`,
      method: 'get',
      params: obj,
    });
  },
  // 学生学情 全科成绩趋势图
  getScoreTrend(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/studentSituation/scoreTrend`,
      method: 'get',
      params: obj,
    });
  },
  // 学生学情 全科：学科均衡度
  getSubjectBalance(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/studentSituation/subjectBalance`,
      method: 'get',
      params: obj,
    });
  },
  // 获取学校 年级 科目下的班级
  getSubjectclass() {
    return Vue.http({
      url: `${this.url}/v1/school/subjectclass`,
      method: 'get',
      params: {
        school_id: sessionStorage.getItem(`${localStoragePrefix}user_school_id`),
        teacher_id: sessionStorage.getItem(`${localStoragePrefix}user_id`),
        accesstoken: sessionStorage.getItem(`${localStoragePrefix}session_id`),
      },
    });
  },
  // 学情分析:学业成绩发展趋势
  getEvalutionTrend(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/analysis/evalutionTrend`,
      method: 'get',
      params: obj,
    });
  },
  // 学情分析:学科均衡性分析
  getEvalutionAnalysis(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/analysis/evalutionAnalysis`,
      method: 'get',
      params: obj,
    });
  },
  // 学情分析:各学科学业分层对比
  getEvalutionCompare(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/analysis/evalutionCompare`,
      method: 'get',
      params: obj,
    });
  },

  // 获取图片ststoken
  getStsToken() {
    return Vue.http({
      url: `${this.url}/v1/pad/sts/token`,
      method: 'get',
      params: {
        accesstoken: 'Bnst2wCwE3VjgK0gBbS9cL5CKf2gk8TI',
      },
    });
  },

  //获取所有科目
  getSubject() {
    return Vue.http({
      url: `${this.url}/v1/subject/all`,
      method: 'get',
      params: {
        accesstoken: 'Bnst2wCwE3VjgK0gBbS9cL5CKf2gk8TI',
      },
    });
  },

  // 学科学情概述
  getSubjectOverview(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/subjectSituation/overview`,
      method: 'get',
      params: obj,
    });
  },

  // 班级报告 成绩单 导出错题
  exportWrongTopics(obj) {
    return Vue.http({
      url: `${this.url}/v1/report/student`,
      method: 'get',
      params: obj,
    });
  },

  // 生成学期报告单
  generateReport(obj) {
    return Vue.http({
      url: `${this.url}/v1/report/class`,
      method: 'get',
      params: obj,
    });
  },

  // 发送学期报告单 /v1/report/sendpad
  sendReport(exam_id, subject_id) {
    return Vue.http({
      url: `${this.url}/v1/report/sendpad`,
      method: 'post',
      params: {
        exam_id,
        subject_id,
      },
    });
  },

  // 题块划分获取详情
  getblockInfo(F_exam_id, F_subject_id) {
    return Vue.http({
      url: `${this.url}/v1/answersheet/blockinfo`,
      method: 'get',
      params: {
        F_exam_id,
        F_subject_id,
      },
    });
  },

  // 设置题块划分
  setblockInfo(formData) {
    return Vue.http({
      url: `${this.url}/v1/answersheet/blockinfo`,
      method: 'post',
      body: formData,
    });
  },

  // 返回到题块设置页面中
  backToBlock(exam_id, subject_id) {
    return Vue.http({
      url: `${this.url}/v1/marker/clear`,
      method: 'post',
      params: {
        exam_id,
        subject_id,
      },
    });
  },

  // 复用答题卡
  reuseAnswerSheet(formData) {
    formData.append('F_teacher_id', sessionStorage.getItem(`${localStoragePrefix}user_id`));
    return Vue.http({
      url: `${this.url}/v1/answersheet/reuse`,
      method: 'post',
      body: formData,
    });
  },

  // 下载答题卡 PDF 格式
  downloadAnswerSheet(id, col_type, title) {
    return Vue.http({
      url: `${this.NEW_PDF_URL}/newpdf`,
      method: 'get',
      params: {
        id,
        col_type,
        title,
        is_single: 1,
      },
    });
  },
   // 下载学生答题卡图片集合
   downloadAnswerSheetCollection(params) {
    params['F_teacher_id'] =  sessionStorage.getItem(`${localStoragePrefix}user_id`);
    return Vue.http({
      url: `${this.url}/v1/joint/school/downloadAnswerSheet`,
      method: 'get',
      params: params,
    });
  },

  // 更新后台的编辑和引用状态
  updateAnserSheetStatus(formData) {
    return Vue.http({
      url: `${this.url}/v1/answersheet/info`,
      method: 'post',
      body: formData,
    });
  },

  updateAnserSheetStatusGS(formData) {
    return Vue.http({
      url: `${this.url}/v1/answersheet/infoGS`,
      method: 'post',
      body: formData,
    });
  },

  // 删除答题卡
  deleteAnswerSheet(json) {
    return Vue.http({
      url: `${this.url}/v1/answersheet`,
      method: 'delete',
      body: json,
    });
  },

  // 下载模版
  // F_exam_id,F_subject_id,F_school_id,F_sheet_id,F_import_content
  downLoadTemplate(data) {
    return Vue.http({
      url: `${this.url}/v1/exam/import/score/template`,
      method: 'post',
      body: data,
    });
  },

  // 上传成绩接口
  upLoadScore(data) {
    return Vue.http({
      url: `${this.url}/v1/exam/import/score/upload`,
      method: 'post',
      body: data,
    });
  },

  // 上传排名接口
  upLoadRank(data) {
    return Vue.http({
      url: `${this.url}/v1/scorereport/importOtherRank`,
      method: 'post',
      body: data,
    });
  },

  // 上传新高考等级分
  upLoadLevelScore(data) {
    return Vue.http({
      url: `${this.url}/v1/scorereport/importStudentLevelScore`,
      method: 'post',
      body: data,
    });
  },

  // 导入成绩页面导出成绩
  exportScore(obj) {
    return Vue.http({
      url: `${this.url}/v1/exam/import/score/export`,
      method: 'get',
      params: obj,
    });
  },

  // 导入成绩上传记录
  getUploadHistory(params) {
    return Vue.http({
      url: `${this.url}/v1/exam/import/score/history`,
      method: 'get',
      params,
    });
  },

  // 导入成绩人数状态
  getUploadStatus(params) {
    return Vue.http({
      url: `${this.url}/v1/exam/import/score/status`,
      method: 'get',
      params,
    });
  },

  // 导入成绩更新缺考状态
  updateAbsent(data) {
    return Vue.http({
      url: `${this.url}/v1/exam/import/score/absent`,
      method: 'post',
      body: data,
    });
  },

  // 导入成绩结束导入
  confirmUpload(params) {
    return Vue.http({
      url: `${this.url}/v1/exam/import/score/confirm`,
      method: 'get',
      params,
    });
  },

  // 班级管理考生复用获取考试列表
  // school_id
  getMultiplex(params) {
    return Vue.http({
      url: `${this.url}/v1/school/multiplex/list`,
      method: 'get',
      params,
    });
  },

  // 确定班级管理考生复用
  // old_exam_id
  // new_exam_id
  // school_id
  confirmMultiplex(params) {
    return Vue.http({
      url: `${this.url}/v1/school/multiplex/do`,
      method: 'post',
      params,
    });
  },

  // 使用教务信息作为考试信息
  confirmUse(exam_id, school_id) {
    return Vue.http({
      url: `${this.url}/v1/school/multiplex/teaching`,
      method: 'post',
      params: {
        exam_id,
        school_id,
      },
    });
  },

  login(formData) {
    return Vue.http({
      url: `${this.loginUrl}/v1/auth/login`,
      method: 'post',
      body: formData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },

  swtichRole(params) {
    return Vue.http({
      url: `${this.loginUrl}/v1/user2/jointSwitchUser`,
      method: 'post',
      body: getFormData(params),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },

  /**
   * 获取该用户其他身份的 user_id，如他是考务人员，
   * 通过该接口获取他是阅卷老师身份的 user_id
   */
  getOtherRoleInfo(params) {
    return Vue.http({
      url: `${this.url
        }/v1/flow_forward/${isTestHost(this.url) ? 'ta-single-test' : 'ta'}/get/v1/teaching/joint/user/loginInfo`,
      method: 'post',
      body: qs.stringify(params),
    });
  },

  // 给手机发送登录验证码
  sendPhoneCode(formData) {
    return Vue.http({
      url: `${this.loginUrl}/v1/auth/sendPhoneCode`,
      method: 'post',
      body: formData,
    });
  },

  // 教研人员登录后获取他的权限范围
  // Sat Sep 16 2023 09:44:47 GMT+0800 (中国标准时间)：因单校版业务功能调整，不再使用该接口
  getUserRange(params) {
    return Vue.http({
      url: `${this.url
        }/v1/flow_forward/${isTestHost(this.url) ? 'ta-single-test' : 'ta'}/get/v1/teaching/joint/getUserPermission`,
      method: 'post',
      body: qs.stringify(params),
    });
  },

  getPermission(params) {
    return Vue.http({
      url: `${this.url}/v1/flow_forward/${isTestHost(this.url) ? 'ta-single-test' : 'ta'}/get/v1/teaching/joint/getUserPermission2`,
      method: 'post',
      body: qs.stringify(params),
    });
  },

  // 获取阅卷老师的城市限制范围
  getMarkingTeacherCity(params) {
    return Vue.http({
      url: `${this.url
        }/v1/marker/area/data`,
      method: 'get',
      params,
    });
  },

  // 区域大数据平台转跳登录
  thirdLogin(formData) {
    return Vue.http({
      url: `${this.loginUrl}/v1/auth/third_login`,
      method: 'post',
      body: formData,
    });
  },

  // 更新弹框提示 v1/update/note
  versionUpdate() {
    return Vue.http({
      url: `${this.url}/v1/update/note`,
      method: 'get',
    });
  },

  // 学情分析:导出班级共性错题
  exportCommonWrongQuestion(obj) {
    return Vue.http({
      url: `${this.url
        }/v1/academic/subjectSituation/commonWrongQuestion/export`,
      method: 'get',
      params: obj,
    });
  },

  // 学情分析: 学生学情 错题本导出
  exportWrongBook(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/studentSituation/wrongBook/export`,
      method: 'get',
      params: obj,
    });
  },
  // 新的错题本导出接口（个人）
  exportWrongBookPerson(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/studentSituation/wrongBook/export3`,
      method: 'post',
      body: qs.stringify(obj),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  // 新的错题本导出接口（班级）
  exportWrongBookClass(obj) {
    return Vue.http({
      url: `${this.url}/v1/academic/studentSituation/wrongBook/export1/batch`,
      method: 'post',
      body: qs.stringify(obj),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  // 获取vip名单
  getVipsList(info) {
    return Vue.http({
      url: `${this.url}/v1/wrongbook/vips`,
      method: 'get',
      params: info,
    });
  },
  rollBack(rollInfo) {
    return Vue.http({
      url: `${this.url}/v1/exams/rollback`,
      method: 'post',
      params: rollInfo,
    });
  },
  // 获取课题组长监管列表
  getQuestionMasterList(masterId) {
    return Vue.http({
      url: `${this.url}/v1/questionmaster/list`,
      method: 'get',
      params: masterId,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  // 课题组长单场考试的监管信息
  getSingleTestInfo(param) {
    return Vue.http({
      url: `${this.url}/v1/questionmaster/status`,
      method: 'get',
      params: param,
    });
  },
  // 随机抽查阅卷情况
  getSpotCheckInfo(param) {
    return Vue.http({
      url: `${this.url}/v1/questionmaster/spotcheck`,
      method: 'get',
      params: param,
    });
  },
  // 获取已抽查数量\总驳回数\总阅卷数
  getSpotCheckCount(params) {
    return Vue.http({
      url: `${this.url}/v1/marker/spot/check/get`,
      method: 'get',
      params,
    });
  },
  // 驳回阅卷
  refuteMarking(param) {
    return Vue.http({
      url: `${this.url}/v1/questionmaster/review`,
      method: 'post',
      params: param,
    });
  },

  /* ------------------------------------------------------------------------ */
  // 多校联考

  /**
   * 获取可选地区列表
   * @param {number} parent_id - 父地区id，默认传0
   * @param {number} level - 地区层级，1：省，2：省市，3：省市区
   * @returns {Promise}
   */
  getDistrictList(parent_id, level) {
    return Vue.http({
      url: `${this.url}/v1/school/district/condition`,
      method: 'get',
      params: {
        parent_id,
        level,
      },
    });
  },

  // 获取一场考试的地区列表
  getExamDistricts(exam_id) {
    return Vue.http({
      url: `${this.url}/v1/scorereport/districts`,
      method: 'get',
      params: {
        exam_id,
      },
    });
  },

  // 获取一场考试选定地区的学校、班级列表
  getSchoolAndClass(params) {
    return Vue.http({
      url: `${this.url}/v1/scorereport/schoolclasses2`,
      method: 'get',
      params,
    });
  },

  // 根据区域id获取map
  getDistrictInfo(params) {
    return Vue.http({
      url: `${this.url}/v1/school/district/info`,
      method: 'get',
      params,
    });
  },

  // 根据地区和搜索内容获取学校列表(分页)
  getDistrictSchools(params) {
    return Vue.http({
      url: `${this.url}/v1/flow_forward/${isTestHost(this.url) ? 'ta-single-test' : 'ta'}/post/v1/teaching/joint/school/list`,
      method: 'post',
      body: qs.stringify(params),
    });
  },

  // 根据地区和搜索内容获取学校列表(不分页)
  getDistrictSchools2(params) {
    return Vue.http({
      url: `${this.url}/v1/flow_forward/${isTestHost(this.url) ? 'ta-single-test' : 'ta'}/post/v1/teaching/joint/school/list2`,
      method: 'post',
      body: qs.stringify(params),
    });
  },

  // 更新一场考试的参考学校
  updateExamSchools(data) {
    return Vue.http({
      url: `${this.url}/v1/joint/school/add`,
      method: 'post',
      body: data,
    });
  },

  /**
   * 根据区域信息获取学校列表
   * @param {number} province_id - 省份ID
   * @param {number} city_id - 城市ID
   * @param {number} district_id - 地区ID
   * @returns {Promise}
   */
  getDistrictSchoolList(province_id, city_id, district_id) {
    return Vue.http({
      url: `${this.url}/v1/school/district/schools`,
      method: 'get',
      params: {
        province_id,
        city_id,
        district_id,
      },
    });
  },

  /**
   * 添加联考学校模板
   * @param {string} name - 模板名字
   * @param {any} templateData - 模板数据
   * @returns {Promise}
   */
  addJointSchoolTemplate(name, templateData) {
    return Vue.http({
      url: `${this.url}/v1/school/templete/add`,
      method: 'post',
      params: {
        teacher_id: sessionStorage.getItem(`${localStoragePrefix}user_id`),
        name,
      },
      body: JSON.stringify(templateData),
    });
  },

  /**
   * 获取联考学校模板列表
   * @returns {Promise}
   */
  getJointSchoolTemplateList() {
    return Vue.http({
      url: `${this.url}/v1/school/templete/get`,
      method: 'get',
      params: {
        teacher_id: sessionStorage.getItem(`${localStoragePrefix}user_id`),
      },
    });
  },

  /**
   * 更新联考学校模板
   * @param {number} id - 模板ID
   * @param {string} name - 模板名字
   * @param {any} templateData - 模板数据
   * @returns {Promise}
   */
  updateJointSchoolTemplate(id, name, templateData) {
    return Vue.http({
      url: `${this.url}/v1/school/templete/update`,
      method: 'post',
      params: {
        id,
        name,
      },
      body: JSON.stringify(templateData),
    });
  },

  /**
   * 删除联考学校模板
   * @param {number[]} templateIDs - 模板ID数组
   * @returns {Promise}
   */
  deleteJointSchoolTemplate(templateIDs) {
    return Vue.http({
      url: `${this.url}/v1/school/templete/delete`,
      method: 'post',
      body: JSON.stringify(templateIDs),
    });
  },

  /**
   * 添加新的科目模板
   * @param {string} name - 模板名字
   * @param {any} templateData - 模板数据
   * @returns {Promise}
   */
  createSubjectTemplate(name, templateData) {
    return Vue.http({
      url: `${this.url}/v1/subject/templete/add`,
      method: 'post',
      params: {
        teacher_id: sessionStorage.getItem(`${localStoragePrefix}user_id`),
        name,
      },
      body: JSON.stringify(templateData),
    });
  },

  /**
   * 获取科目模板
   * @returns {Promise}
   */
  retrieveSubjectTemplate() {
    return Vue.http({
      url: `${this.url}/v1/subject/templete/get`,
      method: 'get',
      params: {
        teacher_id: sessionStorage.getItem(`${localStoragePrefix}user_id`),
      },
    });
  },

  /**
   * 更新科目模板
   * @param {number} id - 模板ID
   * @param {string} name - 模板名字
   * @param {any} templateData - 模板数据
   * @returns {Promise}
   */
  updateSubjectTemplate(id, name, templateData) {
    return Vue.http({
      url: `${this.url}/v1/subject/templete/update`,
      method: 'post',
      params: {
        id,
        name,
      },
      body: JSON.stringify(templateData),
    });
  },

  /**
   * 删除科目模板
   * @param {number[]} templateIDs - 模板ID数组
   * @returns {Promise}
   */
  deleteSubjectTemplate(templateIDs) {
    return Vue.http({
      url: `${this.url}/v1/subject/templete/delete`,
      method: 'post',
      body: JSON.stringify(templateIDs),
    });
  },

  /**
   * 获取联考学生考务基本信息
   */
  getExamJointSimple(exam_id) {
    return Vue.http({
      url: `${this.url}/v1/school/list/getjoint/simple`,
      method: 'get',
      params: {
        exam_id,
      },
    });
  },

  /**
   * 自动添加考生
   * @param {number} exam_id - 考试ID
   */
  autoAddStudent(exam_id) {
    return Vue.http({
      url: `${this.url}/v1/school/multiplex/teaching/joint`,
      method: 'post',
      params: {
        exam_id,
      },
    });
  },

  /**
   * 删除考试所有学生信息
   * @param {number} exam_id - 考试ID
   */
  deleteAllExamStudent(exam_id) {
    return Vue.http({
      url: `${this.url}/v1/school/student/delete/all`,
      method: 'post',
      params: {
        exam_id,
      },
    });
  },

  /**
   * 获取联考人员管理-简易信息
   * @param {number} exam_id - 考试ID
   * @param {number} subject_id - 科目ID
   */
  getJointTeacherStats(exam_id, subject_id) {
    return Vue.http({
      url: `${this.url}/v1/marker/get/simple`,
      method: 'get',
      params: {
        exam_id,
        subject_id,
      },
    });
  },

  /**
   * 获取联考考务人员列表
   * @param {number} exam_id - 考试ID
   * @param {number} [subject_id] - 科目ID
   * @param {number} [province_id] - 省份ID
   * @param {number} [city_id] - 市ID
   * @param {number} [district_id] - 县区ID
   * @param {string} [school_id] - 学校ID
   * @param {number} [page] - 分页页码
   */
  getJointTeacherList(
    exam_id,
    subject_id,
    province_id,
    city_id,
    district_id,
    school_id,
    page,
    limit = 20,
  ) {
    return Vue.http({
      url: `${this.url}/v1/marker/get/list`,
      method: 'get',
      params: {
        exam_id,
        subject_id,
        province_id,
        city_id,
        district_id,
        school_id,
        page,
        limit,
      },
    });
  },

  /**
   * 导入人员
   * @param {number} exam_id - 考试ID
   * @param {number} subject_id - 科目ID
   * @param {File} marker_file - 人员分工Excel表
   */
  importJointTeacher(exam_id, subject_id, marker_file) {
    const body = new FormData();
    body.append('marker_file', marker_file);
    return Vue.http({
      url: `${this.url}/v1/marker/translate/markers`,
      method: 'post',
      params: {
        exam_id,
        subject_id,
      },
      body,
    });
  },

  /**
   * 删除全部人员
   * @param {number} exam_id - 考试ID
   */
  deleteAllTeacher(exam_id) {
    return Vue.http({
      url: `${this.url}/v1/marker/delete/all`,
      method: 'post',
      params: {
        exam_id,
      },
    });
  },

  /**
   * 删除人员
   * @param {string[]} staffIDs - 人员ID数组
   */
  deleteStaff(staffIDs) {
    return Vue.http({
      url: `${this.url}/v1/marker/delete`,
      method: 'post',
      body: JSON.stringify(staffIDs),
    });
  },

  /**
   * 获取这场联考的学校列表
   * @param {number} exam_id - 考试ID
   */
  getJointSchoolList(exam_id) {
    return Vue.http({
      url: `${this.url}/v1/school/joint/list`,
      method: 'get',
      params: {
        exam_id,
      },
    });
  },

  /**
   * 获取考试缺考信息名单 params
   * @param {number} exam_id - 考试ID
   * @param {number} subject_id - 科目ID
   * @param {number} school_id - 学校ID
   * @param {string} class_id - 班级ID
   * @param {number} page - 分页页码
   * @param {number} limit - 分页长度
   */
  getMissingList(params) {
    return Vue.http({
      url: `${this.url}/v1/result/missing/list`,
      method: 'get',
      params,
    });
  },

  // 获取阅卷扫描表格信息
  getScanTableInfo(param) {
    return Vue.http({
      url: `${this.url}/v1/desktop/marking/detail`,
      method: 'get',
      params: param,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  // 获取阅卷表格中单个学校的参加考试的所有班级
  getSingleSchoolClassInfo(param) {
    return Vue.http({
      url: `${this.url}/v1/desktop/school/classes`,
      method: 'get',
      params: param,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  // 根据班级id数据获取班级名字
  getEveryClassName(param) {
    return Vue.http({
      url: `${this.url}/v1/school/classes/list`,
      method: 'get',
      params: param,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  // 单个学校阅卷情况 或者 单个班级的阅卷情况
  getSingleSchoolOrClassPaperInfo(param) {
    return Vue.http({
      url: `${this.url}/v1/desktop/joint/marking/detail/school`,
      method: 'get',
      params: param,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  // 单个学校阅卷情况 或者 单个班级的阅卷情况
  getBlockList(param, isPGYJ = false) {
    // 批改阅卷不传master_id
    if (isPGYJ) {
      delete param['master_id']
    }
    return Vue.http({
      url: `${this.url}/v1/marker/get/task/list`,
      method: 'get',
      params: param,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  // 异常处理中心获取科目异常列表
  getSubjectsErrorList(params) {
    return Vue.http({
      url: `${this.errorUrl}/v1/abnormal_sheet/get_exam`,
      method: 'get',
      params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  // 获取某场考试异常tag数据
  getTagType(params) {
    return Vue.http({
      url: `${this.errorUrl}/v1/abnormal_sheet/get_tag_type`,
      method: 'get',
      params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  // 某场考试分页获取异常卷
  getErrorPaper(params) {
    return Vue.http({
      url: `${this.errorUrl}/v1/abnormal_sheet/get_sheet`,
      method: 'get',
      params,
      // headers: {
      //   'Content-Type': 'application/x-www-form-urlencoded'
      // }
    });
  },
  // 单题处理时获取试卷的问题题块
  getErrorBlock(params) {
    return Vue.http({
      url: `${this.errorUrl}/v1/abnormal_sheet/get_block`,
      method: 'get',
      params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  // 上传图片获取坐标信息
  coordAnswerSheet(params) {
    return Vue.http({
      url: `${this.url}/v1/desktop/coord`,
      method: 'post',
      body: params,
    });
  },
  // 替换问题题块（单题处理时）
  replaceErrorBlock(data) {
    return Vue.http({
      url: `${this.errorUrl}/v1/abnormal_sheet/replace_block`,
      method: 'post',
      body: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  // 标记缺考
  markAbsent(params, data) {
    return Vue.http({
      url: `${this.errorUrl}/v1/abnormal_sheet/sign_missing`,
      method: 'post',
      params,
      body: data,
    });
  },
  // 替换试卷
  replacePaper(data) {
    return Vue.http({
      url: `${this.errorUrl}/v1/abnormal_sheet/replace_sheet`,
      method: 'post',
      body: data,
    });
  },
  // 获取问题卷操作记录
  getErrorRecordList(data) {
    return Vue.http({
      url: `${this.errorUrl}/v1/abnormal_sheet/get_record_stat`,
      method: 'get',
      params: data,
    });
  },
  // 删除异常卷
  deleteErrorPaper(params) {
    return Vue.http({
      url: `${this.errorUrl}/v1/abnormal_sheet/delete_sheet`,
      method: 'post',
      params,
    });
  },
  // 获取题块的阅卷记录
  getBlockRecord(param) {
    return Vue.http({
      url: `${this.url}/v1/marker/block/record`,
      method: 'get',
      params: param,
    });
  },
  // 获取题块在记录中的位置
  getBlockRecordPosition(param) {
    return Vue.http({
      url: `${this.url}/v1/marker/block/record/position`,
      method: 'get',
      params: param,
    });
  },
  // 撤回上一步操作
  resetLastOne(params) {
    return Vue.http({
      url: `${this.errorUrl}/v1/abnormal_sheet/undo_replace_block`,
      method: 'post',
      params,
    });
  },
  // 标记破损
  markPaperDamaged(params) {
    return Vue.http({
      url: `${this.errorUrl}/v1/abnormal_sheet/change_block_tag`,
      method: 'post',
      params,
    });
  },
  // 整卷处理上传所有题块
  uploadAllBlock(params, data) {
    return Vue.http({
      url: `${this.errorUrl}/v1/abnormal_sheet/marking`,
      method: 'post',
      params,
      body: data,
    });
  },
  // 搜索框远程搜索获取学生列表
  // `${this.url}/v1/school/student/all2`
  fetchStudentList(params) {
    return Vue.http({
      url: `${this.url}/v1/school/student/all2`,
      method: 'get',
      params,
    });
  },
  // 给试卷标记
  markPaper(params, data) {
    return Vue.http({
      url: `${this.errorUrl}/v1/abnormal_sheet/mark_replace`,
      method: 'post',
      params,
      body: data,
    });
  },
  // 获取标记的试卷
  getMarkedPaper(params) {
    return Vue.http({
      url: `${this.errorUrl}/v1/abnormal_sheet/get_mark_replace`,
      method: 'get',
      params,
    });
  },
  // 监管模式下获取简易老师信息
  getBriefExamInfoBasedOnTypeOne(params) {
    return Vue.http({
      url: `${this.url}/v1/marker/simple/info`,
      method: 'get',
      params,
    });
  },
  // 获取题块的平均分布
  getBlockDistribution(params) {
    return Vue.http({
      url: `${this.url}/v1/marker/score/distribution`,
      method: 'get',
      params,
    });
  },
  // 裁剪与纵向拼接题块
  handlePic(data) {
    return Vue.http({
      url: `${this.errorUrl}/v1/abnormal_sheet/crop_block`,
      method: 'post',
      body: data,
    });
  },
  // 服务器保存框选坐标
  saveCoord(data) {
    return Vue.http({
      url: `${this.url}/v1/desktop/templete`,
      method: 'post',
      body: data,
    });
  },
  // 获取扫描过的学校
  getSchoolList(params) {
    return Vue.http({
      url: `${this.errorUrl}/v1/abnormal_sheet/get_machine_school`,
      method: 'get',
      params,
    });
  },
  // 标记处理不了的特殊卷
  markSpecial(params) {
    return Vue.http({
      url: `${this.url}/v1/abnormal_sheet/mark_special`,
      method: 'post',
      params,
    });
  },
  getSpecialPaper(params) {
    return Vue.http({
      url: `${this.errorUrl}/v1/abnormal_sheet/get_mark_special`,
      method: 'get',
      params,
    });
  },
  closeUpload(params) {
    return Vue.http({
      url: `${this.url}/v1/desktop/complete`,
      method: 'post',
      params,
    });
  },

  // 多校联考
  /* ------------------------------------------------------------------------ */
  /** *****
   *  珠三角联考，教研人员相关接口 开始
   *  很多接口转发到精准教学管理后台那边现有的,所以 path 看起来比较怪
   *  ******* */

  /**  根据筛选条件获取考生列表 */
  getJointStudentList(params) {
    params.user_id = sessionStorage[`${localStoragePrefix}user_id`];
    return Vue.http({
      url: `${this.url}/v1/school/list/getjoint`,
      method: 'get',
      params,
    });
  },

  // 考生管理引用教务信息
  importStudent(params) {
    return Vue.http({
      url: `${this.url}/v1/school/multiplex/teaching`,
      method: 'post',
      headers: config,
      body: qs.stringify(params),
    });
  },

  // 考生管理按班级引用考务信息
  importClassesStudent(params) {
    return Vue.http({
      url: `${this.url}/v1/school/class/students/sync`,
      method: 'post',
      params,
      // headers: config,
      // body: qs.stringify(params),
    });
  },

  getImportStatus(params) {
    return Vue.http({
      url: `${this.url}/v1/exams/create/confirm`,
      method: 'get',
      params,
    });
  },

  // 阅卷老师管理引用教务信息
  syncTeacher(params) {
    return Vue.http({
      url: `${this.url}/v1/joint/school/teacher/sync_from_ta`,
      method: 'post',
      headers: config,
      body: qs.stringify(params),
    });
  },

  // 更改学生信息
  updateStudent(params) {
    return Vue.http({
      url: `${url}/v1/joint/school/student`,
      method: 'put',
      headers: config,
      body: qs.stringify(params),
    });
  },

  //oldyang
  // 更改学生不参与统计的科目
  updateStudentOut(params) {
    return Vue.http({
      url: `${url}/v1/exam/student/out`,
      method: 'put',
      headers: config,
      body: qs.stringify(params),
    });
  },

  addStudent(params) {
    return Vue.http({
      url: `${url}/v1/joint/school/student`,
      method: 'post',
      headers: config,
      body: qs.stringify(params),
    });
  },

  // 删除学生（单个/多个/清空）
  deleteStudent(params, body) {
    return Vue.http({
      url: `${this.url}/v1/joint/student`,
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
      },
      params,
      body,
    });
  },

  exportStudentList(params) {
    return Vue.http({
      url: `${this.url}/v1/school/list/getjoint/export`,
      method: 'get',
      params,
      responseType: 'blob',
    });
  },

  exportStudentList2(params) {
    return Vue.http({
      url: `${this.url}/v1/school/list/getjoint/export2`,
      method: 'get',
      params,
      responseType: 'blob',
    });
  },

  exportMarkingTask(params) {
    return Vue.http({
      url: `${this.url}/v1/joint/task/list/export`,
      method: 'get',
      params,
      responseType: 'blob',
    });
  },

  // getJointMarkingTeacherList(params) {
  //   return Vue.http({
  //     url: `${this.url}/v1/joint/teacher/list`,
  //     method: 'get',
  //     params
  //   });
  // },

  // 获取要同步老师信息的时候，有哪些筛选条件（年级、科目）
  getTeacherSyncCondition(params) {
    return Vue.http({
      url: `${this.url}/v1/flow_forward/${isTestHost(this.url) ? 'ta-single-test' : 'ta'}/get/v1/teaching/joint/teacher/sync/condition`,
      method: 'post',
      body: qs.stringify(params),
    });
  },

  // 获取可同步的老师列表
  getSyncTeacherList(params) {
    return Vue.http({
      url: `${this.url}/v1/flow_forward/${isTestHost(this.url) ? 'ta-single-test' : 'ta'}/post/v1/teaching/joint/teacher/sync`,
      method: 'post',
      body: qs.stringify(params),
    });
  },

  // 从教务端同步阅卷员（选择老师）
  syncTeacherFromTA(data) {
    return Vue.http({
      url: `${this.url}/v1/joint/school/teacher/sync_from_ta`,
      method: 'post',
      body: data,
    });
  },

  getJointMarkingTeacherList(params) {
    params.user_id = sessionStorage[`${localStoragePrefix}user_id`];
    return Vue.http({
      url: `${this.url}/v1/joint/school/teacher/list`,
      method: 'get',
      params,
    });
  },

  // 为某场考试批量导入老师
  importMarkingTeacher(data) {
    return Vue.http({
      url: `${url}/v1/joint/school/teacher/import`,
      method: 'post',
      body: data,
    });
  },
  // 为某场考试批量导入学生
  importMarkingStudent(data) {
    return Vue.http({
      url: `${url}/v1/joint/school/student/import`,
      method: 'post',
      body: data,
    });
  },
  // 为某场考试批量导入
  importMarkingStudentEduClass(data) {
    return Vue.http({
      url: `${url}/v1/joint/school/student/educlass/import`,
      method: 'post',
      body: data,
    });
  },
  // 为某场考试批量导入学生
  importCompareStudent(data) {
    return Vue.http({
      url: `${url}/v1/joint/school/student/importAndCopmare`,
      method: 'post',
      body: data,
      responseType: 'blob',
    });
  },

  exportTeacherList(params) {
    params.user_id = sessionStorage[`${localStoragePrefix}user_id`];
    return Vue.http({
      url: `${this.url}/v1/joint/school/teacher/export`,
      method: 'get',
      params,
      responseType: 'blob',
    });
  },

  /*
   *  这个接口跟上面 getJointMarkingTeacherList 的区别是：
   *  这个接口是返回全部符合条件的教师列表（不分页）,
   *  且教师信息是精简化的，主要用在设置题块的阅卷老师、题组长
  */
  getJointMarkingTeacherList2(params) {
    return Vue.http({
      url: `${this.url}/v1/joint/teacher/list2`,
      method: 'get',
      params,
    });
  },

  // 获取参与了某场考试的地区列表
  getJointAreaList(params) {
    params.F_user_id = sessionStorage[`${localStoragePrefix}user_id`];
    params.F_accesstoken = sessionStorage[`${localStoragePrefix}session_token`];
    return Vue.http({
      url: `${this.url}/v1/joint/area/list`,
      method: 'get',
      params,
    });
  },

  // 获取参与了某场考试的学校列表
  getJointDsictrctSchools(params) {
    params.F_user_id = sessionStorage[`${localStoragePrefix}user_id`];
    params.F_accesstoken = sessionStorage[`${localStoragePrefix}session_token`];
    return Vue.http({
      url: `${this.url}/v1/joint/district/schools`,
      method: 'get',
      params,
    });
  },

  /**
   * 获取某场考试的学校列表，
   * 主要是不分页、且支持多个省、市、区一起查询
   * @param {Object} params {
   *  exam_id: 考试id （必有）
   *  province_ids: 省id列表字符串（非必有）
   *  city_ids: 市id列表字符串 （非必有）
   *  district_ids: 区id列表字符串（非必有）
   * }
   */
  getSchools(params) {
    return Vue.http({
      url: `${this.url}/v1/joint/school/list`,
      method: 'post',
      body: qs.stringify(params),
      headers: config,
    });
  },

  // 添加一个阅卷老师(弃用)
  // addTeacher(params) {
  //   return Vue.http({
  //     url: `${url}/v1/flow_forward/ta/post/v1/teaching/joint/teacher`,
  //     method: 'post',
  //     body: qs.stringify(params),
  //     headers: config
  //   });
  // },

  addTeacher(params) {
    return Vue.http({
      url: `${url}/v1/joint/school/teacher`,
      method: 'post',
      body: qs.stringify(params),
      headers: config,
    });
  },

  getExamSubjectStatus(params) {
    return Vue.http({
      url: `${this.url}/v1/joint/exam/subject/status`,
      method: 'get',
      params,
    });
  },

  controlImport(params) {
    return Vue.http({
      url: `${this.url}/v1/joint/school/import/control`,
      method: 'get',
      params,
    });
  },

  // 编辑某个阅卷老师
  editTeacher(params) {
    return Vue.http({
      url: `${url}/v1/joint/school/teacher`,
      method: 'put',
      body: qs.stringify(params),
      headers: config,
    });
  },

  // 删除某个阅卷老师
  deleteTeacher(params) {
    return Vue.http({
      url: `${this.url}/v1/joint/school/teacher`,
      method: 'delete',
      params,
    });
  },

  // 获取阅卷任务分配情况
  getMarkingTaskList(params) {
    return Vue.http({
      url: `${this.url}/v1/joint/task/list`,
      method: 'get',
      params,
    });
  },

  // 获取阅卷分配情况
  getMarkerDetail(params) {
    return Vue.http({
      url: `${this.url}/v1/joint/task/alloc/marker/detail`,
      method: 'get',
      params,
    });
  },

  // 获取当前的题块阅卷进度详情
  getBlockMarkingProgress(params) {
    return Vue.http({
      url: `${this.url}/v1/joint/task/progress/block`,
      method: 'get',
      params,
    });
  },

  // 重新分配题块的阅卷任务
  updateMarkerDetail(data) {
    return Vue.http({
      url: `${this.url}/v1/joint/task/alloc/marker/realloc`,
      method: 'post',
      body: data,
    });
  },

  // 批量导入阅卷任务
  importMarkingTask(data) {
    return Vue.http({
      url: `${this.url}/v1/joint/task/alloc/batch`,
      method: 'post',
      body: data,
      // body: qs.stringify(data),
      // headers: config
    });
  },

  // 对某个题块设置阅卷老师
  setBlockTeachers(params) {
    const user_id = window.sessionStorage.getItem('examination_user_id');
    params = {
      ...params,
      user_id,
    };
    return Vue.http({
      url: `${url}/v1/joint/task/alloc/marker`,
      method: 'post',
      body: qs.stringify(params),
      headers: config,
    });
  },

  // 对某个题块设置题块组长
  setBlockMasters(params) {
    const user_id = window.sessionStorage.getItem('examination_user_id');
    params = {
      ...params,
      user_id,
    };
    return Vue.http({
      url: `${url}/v1/joint/task/alloc/master`,
      method: 'post',
      body: qs.stringify(params),
      headers: config,
    });
  },

  // 一键设置老师到所有题块
  setTeachersToAllBlocks(params) {
    return Vue.http({
      url: `${url}/v1/joint/task/alloc/allBlock`,
      method: 'post',
      body: qs.stringify(params),
      headers: config,
    });
  },

  // 复制阅卷任务
  copyBlockTask(params) {
    const user_id = window.sessionStorage.getItem('examination_user_id');
    params = {
      ...params,
      user_id,
    };
    return Vue.http({
      url: `${this.url}/v1/joint/task/alloc/copy`,
      method: 'post',
      body: qs.stringify(params),
      headers: config,
    });
  },

  /** ***** 珠三角联考，教研人员相关接口 结束 ******* */
  /**
   * 获取评卷进度
   * @param {number} exam_id - 考试ID
   * @param {number} subject_id - 科目ID
   * @param {string} marker_id - 教师ID
   * @param {number} [is_question_master] - 是否为题组长，1：是，0：不是，默认0
   */
  getMarkingProgress(exam_id, subject_id, marker_id, is_question_master) {
    return Vue.http({
      url: `${this.url}/v1/marker/score/progress`,
      method: 'get',
      params: {
        exam_id,
        subject_id,
        marker_id,
        is_question_master,
      },
    });
  },

  /**
   * 获取教师当前题块分数曲线数据
   * @param {number} exam_id - 考试ID
   * @param {number} subject_id - 科目ID
   * @param {number} block_id - 题块ID
   * @param {string} marker_id - 教师ID
   * @param {number} [is_question_master] - 是否为题组长，1：是，0：不是，默认0
   */
  getScoreCurve(exam_id, subject_id, block_id, marker_id, is_question_master) {
    return Vue.http({
      url: `${this.url}/v1/marker/score/curve`,
      method: 'get',
      params: {
        exam_id,
        subject_id,
        block_id,
        marker_id,
        is_question_master,
      },
    });
  },

  /**
   * 获取要回评的题块数据
   * @param {number} exam_id - 考试ID
   * @param {number} subject_id - 科目ID
   * @param {string} marker_id - 教师ID
   * @param {number} block_id - 题块ID
   * @param {number} trend_index - 0：回评上一份，1：回评下一份
   * @param {number} [is_question_master] - 是否为题组长，1：是，0：不是，默认0
   * @param {number} [task_detail_id] - 题块detail的主id，可不传返回最后批改一题
   */
  getRemarkBlock(
    exam_id,
    subject_id,
    marker_id,
    block_id,
    trend_index,
    is_question_master = 0,
    task_detail_id,
  ) {
    const params = {
      exam_id,
      subject_id,
      block_id,
      marker_id,
      trend_index,
      is_question_master,
    };
    if (task_detail_id) {
      params.task_detail_id = task_detail_id;
    }
    return Vue.http({
      url: `${this.url}/v1/marker/trend/task/detail`,
      method: 'get',
      params,
    });
  },

  /**
   * 获取题块总进度
   * @param {number} exam_id - 考试ID
   * @param {number} subject_id - 科目ID
   * @param {number} block_id - 题块ID
   * @param {number} [is_question_master] - 是否为题组长，1：是，0：不是，默认0
   */
  getTotalProgress(exam_id, subject_id, block_id, is_question_master) {
    const params = { exam_id, subject_id, block_id };
    if (is_question_master) {
      params.is_question_master = is_question_master;
    }
    return Vue.http({
      url: `${this.url}/v1/marker/block/progress`,
      method: 'get',
      params,
      before(request) {
        if (lastProgressRequest) {
          lastProgressRequest.abort();
        }
        lastProgressRequest = request;
      },
    });
  },

  /**
   * 获取抽查进度
   * @param {number} exam_id - 考试ID
   * @param {number} subject_id - 科目ID
   * @param {number} block_id - 题块ID
   * @param {string} marker_id - 教师ID
   */
  getSpotProgress(exam_id, subject_id, block_id, marker_id) {
    return Vue.http({
      url: `${this.url}/v1/marker/spot/check/get`,
      method: 'get',
      params: {
        exam_id,
        subject_id,
        block_id,
        marker_id,
      },
      before(request) {
        if (lastSpotProgressRequest) {
          lastSpotProgressRequest.abort();
        }
        lastSpotProgressRequest = request;
      },
    });
  },

  /**
   * 记录抽查记录
   * @param {number} exam_id - 考试ID
   * @param {number} subject_id - 科目ID
   * @param {number} block_id - 题块ID
   * @param {string} marker_id - 抽查人ID
   * @param {number} task_detail_id - 待抽查题块主键ID
   */
  recordSpot(exam_id, subject_id, block_id, marker_id, task_detail_id) {
    return Vue.http({
      url: `${this.url}/v1/marker/spot/check/record`,
      method: 'post',
      params: {
        exam_id,
        subject_id,
        block_id,
        marker_id,
        task_detail_id,
      },
    });
  },

  sendAbsenceConfirm(params) {
    return Vue.http({
      url: `${this.url}/v1/exams/send/absence/confirm`,
      method: 'post',
      body: qs.stringify(params),
      headers: config,
      // params: {
      //   exam_id,
      //   subject_id,
      //   date
      // }
    });
  },
  // 发布成绩
  releaseResults(params) {
    return Vue.http({
      url: `${this.url}/v1/snapshot/create`,
      method: 'post',
      params,
    });
  },

  /**
   * 获取成绩预审列表
   * @param {number} exam_id - 考试id
   * @param {number} province_id - 省id
   * @param {number} city_id - 市id
   * @param {number} district_id - 区id
   * @param {string} school_id - 学校id
   * @param {string} school_name - 学校名
   * @param {number} status - 成绩预审状态 0-全部;1-未发布;2-已发布;3-已确认
   * @param {number} filter_not_feedback - 过滤异议反馈 0-不过滤;1-过滤
   * @param {number} page - 页数
   * @param {number} limit - 页面大小
   */

  getPretrialManageList(
    exam_id,
    province_id,
    city_id,
    district_id,
    school_id,
    school_name,
    status,
    filter_not_feedback,
    page,
    limit,
  ) {
    return Vue.http({
      url: `${this.url}/v1/joint/pretrial/manage/list`,
      method: 'get',
      params: {
        exam_id,
        province_id,
        city_id,
        district_id,
        school_id,
        school_name,
        status,
        filter_not_feedback,
        page,
        limit,
      },
    });
  },

  /**
   * 发布成绩预审
   * @param {number} exam_id - 考试id
   * @param {string} tips_time - 短信内容里的提醒时间
   * @param {string} city_ids - 市id列表字符串 英文半角逗号分隔
   * @param {string} district_ids - 区id列表字符串 英文半角逗号分隔
   * @param {string} school_ids - 学校id列表字符串 英文半角逗号分隔
   */

  publishPretrialManage(
    exam_id,
    tips_time,
    city_ids,
    district_ids,
    school_ids,
  ) {
    const params = {
      exam_id,
      tips_time,
      city_ids,
      district_ids,
      school_ids,
    };
    return Vue.http({
      url: `${this.url}/v1/joint/pretrial/publish`,
      method: 'post',
      body: qs.stringify(params),
      headers: config,
    });
  },

  /**
   * 重发成绩预审短信通知
   * @param {number} exam_id - 考试id
   * @param {string} school_id - 学校id
   */

  notifySchoolAdmin(exam_id, school_id) {
    const params = { exam_id, school_id };
    return Vue.http({
      url: `${this.url}/v1/joint/pretrial/notifySchoolAdmin`,
      method: 'post',
      body: qs.stringify(params),
      headers: config,
    });
  },

  /**
   * 获取成绩预审记录详情
   * @param {number} exam_id - 考试id
   * @param {string} school_id - 学校id
   */

  pretrialDetail(exam_id, school_id) {
    const params = { exam_id, school_id };
    return Vue.http({
      url: `${this.url}/v1/joint/pretrial/detail`,
      method: 'get',
      params,
    });
  },
  // 获取题块仲裁情况
  getBlockArbitration(params) {
    return Vue.http({
      url: `${this.url}/v1/marker/block/master/detail`,
      method: 'get',
      params,
    });
  },
  // 重新分配双评
  resetDoubleMark(id, arr, subjectId, examId) {
    return Vue.http({
      url: `${this.url}/v1/marker/block/master/reset/`,
      method: 'post',
      params: {
        block_id: id,
        subject_id: subjectId,
        exam_id: examId,
      },
      body: arr,
      headers: config,
    });
  },
  recordSpotSubmit({
    exam_id,
    subject_id,
    block_id,
    marker_id,
    task_detail_id,
    is_rejected,
    is_marked,
    reject_score,
  }) {
    const body = new FormData();
    body.append('exam_id', exam_id);
    body.append('subject_id', subject_id);
    body.append('block_id', block_id);
    body.append('marker_id', marker_id);
    body.append('task_detail_id', task_detail_id);
    body.append('is_rejected', is_rejected);
    body.append('is_marked', is_marked);
    if (is_marked === 1) {
      body.append('reject_score', reject_score);
    }
    return Vue.http({
      url: `${this.url}/v1/marker/spot/check/reject`,
      method: 'post',
      body,
    });
  },
  downloadScoreRangePaper(params) {
    return Vue.http({
      url: `${this.url}/v1/result/paper/typical/images/score`,
      method: 'get',
      params,
      responseType: 'blob',
    });
  },
  /**
   * 获取考试总进度
   * @param {string} examId - 考试ID
   */
  getExamProgress(examId) {
    return Vue.http({
      url: `${this.url}/v1/exams/progress/${sessionStorage.getItem(
        `${localStoragePrefix}user_school_id`,
      )}/${examId}`,
      method: 'get',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },

  // 获取登录用户所绑定的班级、科目信息
  getTeachInfo(params) {
    return Vue.http({
      url: `${this.url}/v1/flow_forward/${isTestHost(this.url) ? 'ta-single-test' : 'ta'}/get/v1/teaching/joint/teacher/relations`,
      method: 'post',
      body: qs.stringify(params),
    });
  },
  // 扫描缺考名单导出(单科)
  exportAbsentList(params) {
    return Vue.http({
      url: `${this.url}/v1/desktop/joint/marking/detail/school/export`,
      method: 'get',
      params,
      responseType: "arraybuffer"
    });
  },
  // 扫描缺卷名单导出(单科)
  exportLackList(params) {
    return Vue.http({
      url: `${this.url}/v1/desktop/joint/marking/detail/school/lackExport`,
      method: 'get',
      params,
      responseType: 'arraybuffer'
    });
  },
  // 扫描缺考名单导出(所有科目)
  exportExamAbsentList(params) {
    return Vue.http({
      url: `${this.url}/v1/desktop/joint/marking/detail/school/all/export`,
      method: 'get',
      params,
      responseType: "arraybuffer"
    })
  },

  /** 单校版首页 */
  getPolyInfo(params) {
    return Vue.http({
      url: `${this.url}/v1/single/index/poly_info`,
      method: 'get',
      params,
    });
  },
  getExamScoreIndex(params) {
    return Vue.http({
      url: `${this.url}/v1/single/index/exam_score_index`,
      method: 'get',
      params,
    });
  },
  getExamScoreSeg(params) {
    return Vue.http({
      url: `${this.url}/v1/single/index/exam_score_seg`,
      method: 'get',
      params,
    });
  },
  getExamScoreTrend(params) {
    return Vue.http({
      url: `${this.url}/v1/single/index/exam_score_trend`,
      method: 'get',
      params,
    });
  },
  getVideoList() {
    return Vue.http({
      url: `${this.url}/v1/desktop/train/video`,
      method: 'get',
    });
  },

  /* 答题卡中心 */
  /**
   * 切换私有/公开
   * @param {number} sheetId - 答题卡id
   */
  toggleSheetVisibility(sheetId, type) {
    const body = new FormData();
    body.append('F_answer_sheet_id', sheetId);
    body.append("F_share_type", type)
    return Vue.http({
      url: `${this.url}/v1/answersheet/share/school`,
      method: 'post',
      body,
    });
  },
  /**
   * 切换锁定状态
   * @param {number} sheetId - 答题卡id
   */
  toggleSheetLock(sheetId) {
    const body = new FormData();
    body.append('F_answer_sheet_id', sheetId);
    return Vue.http({
      url: `${this.url}/v1/answersheet/lock`,
      method: 'post',
      body,
    });
  },
  /**
   * 切换模板状态
   * @param {number} sheetId - 答题卡id
   */
  toggleSheetTemplate(sheetId) {
    const body = new FormData();
    body.append('F_answer_sheet_id', sheetId);
    return Vue.http({
      url: `${this.url}/v1/answersheet/template`,
      method: 'post',
      body,
    });
  },
  // 发布错题之前查询是否绑定
  beforeReleaseQuestions(params) {
    return Vue.http({
      url: `${this.url}/v1/answersheet/srcquestion/exist`,
      method: "get",
      params
    });
  },
  // 推送错题到小程序和APP
  releaseQuestions(examId, subjectId) {
    const body = new FormData();
    body.append("exam_id", examId);
    body.append("subject_id", subjectId);
    return Vue.http({
      url: `${this.url}/v1/result/translate/wrong/book`,
      method: "post",
      body
    });
  },
  // 错题导出时获取城市
  getExportCities() {
    return Vue.http({
      url: `${this.url}/v1/academic/studentSituation/wrongBook/xkwAreaCode`,
      method: "get"
    })
  },
  // 评卷监管导出表格
  exportExcel(
    exam_id,
    subject_id,
    marker_id,
    block_id,
    province_id,
    city_id,
    district_id,
    school_id,
    search,
    type_index,
    currentRoute,
    showHeaderInfo,
    is_not_mark) {
    let param = {
      exam_id,
      subject_id,
      marker_id,
      block_id,
      province_id,
      city_id,
      district_id,
      school_id,
      search,
      type_index,
      is_not_mark,
    };
    if (
      currentRoute.name === 'evaluationManage'
      || currentRoute.path === '/MarkingSupervision'
      || !showHeaderInfo
    ) {
      param = {
        ...param,
        is_admin: 1,
      };
    }
    const arr = Object.keys(param);
    arr.forEach((r) => {
      if (!param[r] && r !== 'type_index') {
        delete param[r];
      }
    });
    return Vue.http({
      url: `${this.url}/v1/marker/spot/check/list/export`,
      method: 'get',
      params: param,
      responseType: 'blob',
    });
  },
  // 获取学生信息
  fetchStudentList(params) {
    return Vue.http({
      url: `${this.url}/v1/school/student/all2`,
      method: 'get',
      params: params,
    });
  },
  // 翻转图片
  rotateImg(param) {
    return Vue.http({
      url: `${this.url}/v1/abnormal_sheet/crop_block`,
      method: 'post',
      body: param,
    });
  },
  // 获取框选后的坐标
  coordAnswerSheet(form) {
    return Vue.http({
      url: `${this.url}/v1/desktop/coord`,
      method: 'post',
      body: form,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  // 修改答案
  changeAnswer(param) {
    return Vue.http({
      url: `${this.url}/v1/checking/fix/object`,
      method: 'post',
      body: param,
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  },
  // 获取某项异常下的异常试卷
  getSingleTabErrorInfo(params) {
    return Vue.http({
      url: `${this.url}/v1/checking/get2`,
      method: 'get',
      params: {
        ...params,
        F_accesstoken: sessionStorage.getItem(`${localStoragePrefix}session_id`),
        F_teacher_id: sessionStorage.getItem(`${localStoragePrefix}user_id`),
        marker_id: sessionStorage.getItem(`${localStoragePrefix}user_id`)
      },
    });
  },
  // 获取每个异常页的数量
  geteveryTabErrorCount(params) {
    return Vue.http({
      url: `${this.url}/v1/checking/simple2`,
      method: 'get',
      params: {
        ...params,
        F_accesstoken: sessionStorage.getItem(`${localStoragePrefix}session_id`),
        F_teacher_id: sessionStorage.getItem(`${localStoragePrefix}user_id`),
        // marker_id: sessionStorage.getItem(`${localStoragePrefix}user_id`)
      },
    });
  },
  // 增加空题块
  addEmptyBlock(params) {
    return Vue.http({
      url: `${this.url}/v1/answersheet/addEmptyBlock`,
      method: 'post',
      body: params,
    });
  },
  // 删除试卷
  reomr(params) {
    return Vue.http({
      url: `${this.url}/v1/desktop/reomr`,
      method: 'post',
      body: params,
    });
  },
  // 删除试卷
  reomrSingle(params) {
    return Vue.http({
      url: `${this.url}/v1/desktop/reomrSingle`,
      method: 'post',
      body: params,
    });
  },
  // 标记为异常卷
  markProblem(params) {
    return Vue.http({
      url: `${this.url}/v1/desktop/problem/sign`,
      method: 'post',
      body: params,
    });
  },
  // 上传试卷
  marking(params) {
    let paramsArr = [];
    for (const key in params) {
      if (key !== "data") {
        paramsArr.push(`${key}=${params[key]}`);
      }
    }
    let finalUrl = `/v1/desktop/marking?${paramsArr.join("&")}`;
    return Vue.http({
      url: `${this.url}${finalUrl}`,
      method: 'post',
      body: JSON.stringify(params.data),
      headers: {
        "Content-Type": "text/plain",
      },
    });
  },
  // 考号匹配
  singleMatch(params) {
    return Vue.http({
      url: `${this.url}/v1/checking/singlematch`,
      method: 'post',
      body: params,
    });
  },
  // 标记缺考
  unscanSign(params) {
    let finalUrl = `/v1/resource/sign?exam_id=${params.exam_id}&subject_id=${params.subject_id}&machine_code=${sessionStorage.getItem("examination_user_id")}`;
    return Vue.http({
      url: `${this.url}${finalUrl}`,
      method: 'post',
      body: JSON.stringify(params.data),
      headers: {
        "Content-Type": "text/plain",
      },
    });
  },
  // 重新设定问题
  resetErrorTypeIndex(params) {
    return Vue.http({
      url: `${this.url}/v1/desktop/change/exception`,
      method: 'post',
      body: params,
    });
  },
  // 获取参加考试的所有学校
  getAllSchoolsOfAExam(params) {
    return Vue.http({
      url: `${this.url}/v1/desktop/school/list`,
      method: 'get',
      params
    })
  },
  // 判断考试是否引用了答题卡
  isAnswerSheetImported(params) {
    return Vue.http({
      url: `${this.url}/v1/answersheet/is/import`,
      method: "get",
      params: params
    })
  },
  // 获取客户端扫描情况
  getDesktopScanInfo(params) {
    return Vue.http({
      url: `${this.url}/v1/desktop/exam`,
      method: "get",
      params
    })
  },
  // 重置客户端某一批次扫描
  clearBatcnScan(params) {
    return Vue.http({
      url: `${this.url}/v1/desktop/clear/`,
      method: "post",
      body: params
    })
  },
  clearImportScore(params) {
    return Vue.http({
      url: `${this.url}/v1/exam/import/score/clear`,
      method: "post",
      body: params
    })
  },
  // 获取学校属于某一个区域
  getCertainDistrict(params) {
    return Vue.http({
      url: `${this.url}/v1/flow_forward/${isTestHost(this.url) ? 'ta-single-test' : 'ta'}/post/v1/teaching/joint/getSchoolInfoByIds2`,
      method: "post",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: qs.stringify(params)
    })
  },

  getAreaSchoolList(params) {
    return Vue.http({
      url: `${this.url}/v1/flow_forward/${isTestHost(this.url) ? 'ta-single-test' : 'ta'}/post/v1/teaching/joint/school/list2`,
      method: "post",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: qs.stringify(params)
    })
  },


  /** 考试进行（成绩报表）部分 start */
  getScorePreviewAll(params) {
    return Vue.http({
      url: `${this.url}/v1/score_report_preview/all`,
      method: "get",
      params: params
    })
  },

  getScorePreviewAlarm(params) {
    return Vue.http({
      url: `${this.url}/v1/score_report_preview/alarm_stat`,
      method: "get",
      params: params
    })
  },

  getPreviewStuCount(params) {
    return Vue.http({
      url: `${this.url}/v1/score_report_preview/stu_count`,
      method: "get",
      params: params
    })
  },

  getPreviewStuList(params) {
    return Vue.http({
      url: `${this.url}/v1/score_report_preview/stu_list`,
      method: "get",
      params: params
    })
  },

  getPreviewAlarmStuList(params) {
    return Vue.http({
      url: `${this.url}/v1/score_report_preview/alarm_list`,
      method: "get",
      params: params
    })
  },

  updatePreviewStatus(params) {
    return Vue.http({
      url: `${this.url}/v1/score_report_preview/update_status`,
      method: "post",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: qs.stringify(params)
    })
  },
  updateAbsentStatus(params) {
    return Vue.http({
      url: `${this.url}/v1/score_report_preview/update_absent_status`,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: qs.stringify(params)
    })
  },

  tagException(params) {
    return Vue.http({
      url: `${this.url}/v1/score_report_preview/tag_exception`,
      method: "post",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: qs.stringify(params)
    })
  },

  deleteScore(params) {
    return Vue.http({
      url: `${this.url}/v1/score_report_preview/delete_score`,
      method: "post",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: qs.stringify(params)
    })
  },

  scoreRollBack(params) {
    return Vue.http({
      url: `${this.url}/v1/score_report_preview/rollback_score`,
      method: "post",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: qs.stringify(params)
    })
  },

  exportPreivewAllStu(params) {
    return Vue.http({
      url: `${this.url}/v1/score_report_preview/export/all_stu`,
      method: 'get',
      params,
      responseType: 'blob',
    });
  },

  exportPreivewMissStu(params) {
    return Vue.http({
      url: `${this.url}/v1/score_report_preview/export/missing_stu`,
      method: 'get',
      params,
      responseType: 'blob',
    });
  },

  getStudentSheetDetail(params) {
    return Vue.http({
      url: `${this.url}/v1/score_report_preview/score_detail`,
      method: 'get',
      params,
    });
  },
  updateScore(data) {
    return Vue.http({
      url: `${this.url}/v1/score_report_preview/update_score`,
      method: 'post',
      body: data,
    });
  },
  batchUpdateScore(data) {
    return Vue.http({
      url: `${this.url}/v1/score_report_preview/batch_update_score`,
      method: 'post',
      body: data,
    });
  },
  /** 考试进行（成绩报表）部分 end */
  batchSetScore(data) {
    return Vue.http({
      url: `${this.url}/v1/result/fix/all/option`,
      method: "post",
      body: data
    })
  },
  batchSetAnswer(data) {
    return Vue.http({
      url: `${this.url}/v1/checking/fix/object/all`,
      method: "post",
      body: data
    })
  },
  getObjective(params) {
    return Vue.http({
      url: `${this.url}/v1/scorereport/choiceScoreRatio`,
      method: "get",
      params
    })
  },
  getOffLine(params) {
    return Vue.http({
      url: `${this.url}/v1/scorereport/getOffLine`,
      method: "get",
      params
    })
  },
  judgeDifferent(data) {
    return Vue.http({
      url: `${this.url}/v1/answersheet/judge/block/score`,
      method: "post",
      body: data
    })
  },
  getSheetDetail(params) {
    return Vue.http({
      url: `${this.answersheetUrl}/v2/answersheet/info`,
      method: "get",
      params
    })
  },
  pdf2Img(data) {
    return Vue.http({
      url: `${this.url}/v1/answersheet/translate/pdf/to/img`,
      method: "post",
      body: data
    })
  },
  cropSheets(data) {
    return Vue.http({
      url: `${this.url}/v1/answersheet/crop/img`,
      method: "post",
      body: data
    })
  },
  translateBlock(data) {
    return Vue.http({
      url: `${this.url}/v1/desktop/reomr/question/translate/block`,
      method: "post",
      body: data
    })
  },
  reCropBlock(data) {
    return Vue.http({
      url: `${this.url}/v1/desktop/recrop/block/for/point`,
      method: "post",
      body: data
    })
  },
  getReportConfig(params) {
    return Vue.http({
      url: `${this.url}/v1/school/report/config`,
      method: 'get',
      params,
    });
  },
  postReportConfig(params) {
    return Vue.http({
      url: `${this.url}/v1/school/report/config`,
      method: 'post',
      body: getFormData(params),
    });
  },
  getExamSubject(params) {
    return Vue.http({
      url: `${this.url}/v1/scorereport/high/examSubject`,
      method: 'get',
      params,
    });
  },
  getMaxScore(params) {
    return Vue.http({
      url: `${this.url}/v1/marker/block/score`,
      method: 'get',
      params,
    });
  },
  exportScorereport(params) {
    return Vue.http({
      url: `${this.url}/v1/scorereport/choiceScoreRatio/export`,
      method: 'get',
      responseType: 'blob',
      params,
    });
  },
  exportOffLine(params) {
    return Vue.http({
      url: `${this.url}/v1/scorereport/choiceScoreRatio/exportOffLine`,
      method: 'get',
      responseType: 'blob',
      params,
    });
  },

  schoolGradeSubjects(params) {
    return Vue.http({
      url: `${this.url}/v1/school/list/getoption`,
      method: 'get',
      params,
    });
  },
  separateExamSubject(data) {
    return Vue.http({
      url: `${this.url}/v1/result/common/separate/exam/data`,
      method: "post",
      body: getFormData(data),
    })
  },
  getCoordExample(params) {
    return Vue.http({
      url: `${this.url}/v1/desktop/template/box`,
      method: 'get',
      params,
    });
  },
  updateAvgCount(data) {
    return Vue.http({
      url: `${this.url}/v1/joint/task/alloc/alloc/update_avg_count`,
      method: "post",
      body: getFormData(data),
    })
  },
  getStageOptions(params) {
    return Vue.http({
      url: `${this.url}/v1/school/stage`,
      method: "get",
      params,
    })
  },
  // 更新考生部分
  getUpdateStudent(data) {
    return Vue.http({
      url: `${this.url}/v1/school/sync/teaching/student/data`,
      method: "post",
      body: getFormData(data)
    })
  },
  getFilterStudents(data) {
    return Vue.http({
      url: `${this.url}/v1/flow_forward/${isTestHost(this.url) ? 'ta-single-test' : 'ta'}/get/v1/teaching/joint/web/students`,
      method: "post",
      headers: config,
      body: qs.stringify(data)
    })
  },
  getClassListBySchoolId(data) {
    return Vue.http({
      url: `${this.url}/v1/flow_forward/${isTestHost(this.url) ? 'ta-single-test' : 'ta'}/get/v1/teaching/joint/getClassListBySchoolId`,
      method: "post",
      body: data,
      headers: {
        "Content-Type": "text/plain",
      }
    })
  },
  matchingStudent(data) {
    return Vue.http({
      url: `${this.url}/v1/school/sync/teaching/student/same/data`,
      method: "post",
      body: getFormData(data)
    })
  },
  resetChoiceScore(data) {
    return Vue.http({
      url: `${this.url}/v1/desktop/refix/option`,
      method: "post",
      body: getFormData(data)
    })
  },

  getHelpResource() {
    return Vue.http({
      url: `${this.url}/v1/help_center/resource`,
      method: 'get',
    })
  },

  resetStudentChoiceResult(data) {
    return Vue.http({
      url: `${this.url}/v1/desktop/refix/option/simple`,
      method: "post",
      body: getFormData(data)
    })
  },
  getPermissionConfig(params) {
    return Vue.http({
      url: `${this.url}/v1/school/authority/config`,
      method: 'get',
      params
    })
  },
  savePermissionConfig(data) {
    return Vue.http({
      url: `${this.url}/v1/school/authority/config`,
      method: "post",
      headers: config,
      body: qs.stringify(data)
    })
  },
  checkSheetIsDone(params) {
    return Vue.http({
      url: `${this.url}/v1/desktop/judge/marking/upload`,
      method: 'get',
      params
    })
  },
  quickUpload(data) {
    return Vue.http({
      url: `${this.url}/v1/desktop/marking/for/upload`,
      method: "post",
      body: getFormData(data)
    })
  },
  getAnswersheetPoints(sheetId) {
    return Vue.http({
      url: `${this.answersheetUrl}/v2/examination/answersheet/points/${sheetId}`,
      method: 'get',
    });
  },
  analyzePdf({ sheetId, data }) {
    return Vue.http({
      url: `${this.answersheetUrl}/v2/examination/answersheet/points/parse_pdf/${sheetId}`,
      method: 'post',
      headers: config,
      body: getFormData(data)
    });
  },
  saveAnswersheetPoints({ sheetId, data }) {
    return Vue.http({
      url: `${this.answersheetUrl}/v2/examination/answersheet/points/${sheetId}`,
      method: 'post',
      body: data
    });
  },
  checkStudentList(params) {
    return Vue.http({
      url: `${this.url}/v1/checking/unhandled_exception/repeat_exam_no`,
      method: 'get',
      params
    })
  },
  getBlockPreviewInfo(params) {
    return Vue.http({
      url: `${this.url}/v1/marker/check/detail`,
      method: "get",
      params
    })
  },
  getPapersOfCertainBlock(params) {
    return Vue.http({
      url: `${this.url}/v1/marker/check/block/list`,
      method: "get",
      params
    })
  },
  getTrendTaksDetail(params) {
    return Vue.http({
      url: `${this.url}/v1/marker/check/trend/task/detail`,
      method: "get",
      params
    })
  },
  //根据考试ID查询班主任及任课教师
  getClassTeachers(exam_id) {
    return Vue.http({
      url: `${this.url}/v1/teaching/classteachers`,
      method: "get",
      params:{
        exam_id
      }
    })
  },
  //保存班主任及任课教师
  saveClassTeachers(data) {
    return Vue.http({
      url: `${this.url}/v1/teaching/classteachers`,
      method: "post",
      params:data
    })
  },
  //删除班主任及任课教师
  deleteClassTeachers(data) {
    return Vue.http({
      url: `${this.url}/v1/teaching/classteachers`,
      method: "delete",
      params:data
    })
  },
  //清空班主任及任课教师
  clearClassTeachers(data) {
    return Vue.http({
      url: `${this.url}/v1/teaching/classteachers/clear`,
      method: "post",
      params:data
    })
  },
   // 上传任课教师接口
   uploadSubjectTeacher(data) {
    return Vue.http({
      url: `${this.url}/v1/teaching/classteachers/upload`,
      method: 'post',
      body: data,
    });
  },
};

export default serve;
