export const ROLE = {
  teacher: 'school',
  manage: 'admin',
};

export const rankList = [
  // { id: 'joint', label: '联考排名' },
  { id: 'city', label: '市排名' },
  // { id: 'district', label: '镇排名' },
  { id: 'district', label: '区排名' },
  { id: 'school', label: '校排名' },
  { id: 'class', label: '班排名' },
]

export const studentColumnList = [
  {
    prop: 'district_name',
    label: '镇区',
    fixed: 'left',
    exportTag: 'district_name',
    requiredColumn: true,
  },
  {
    prop: 'school_name',
    label: '学校',
    minWidth: '250px',
    fixed: 'left',
    exportTag: 'school_name',
    requiredColumn: true,
  },
  {
    prop: 'class_name',
    label: '班级',
    minWidth: '150px',
    fixed: 'left',
    exportTag: 'class_name',
    requiredColumn: true,
  },
  {
    prop: 'student_name',
    label: '考生',
    fixed: 'left',
    minWidth: '94px',
    exportTag: 'student_name',
    requiredColumn: true,
    slotFlag: true,
  },
  {
    prop: 'student_no',
    label: '考号',
    minWidth: '120px',
    fixed: 'left',
    exportTag: 'student_no',
    requiredColumn: true,
  },
]

export const highNavList = [
  { name: '成绩分数表', path: '/gradeFrom' },
  { name: '小题分表', path: '/littleQuestion' },
  { name: '分数段', path: '/gradeSection' },
  { name: '等级分', path: '/levelGrades' },
  //{ name: '基础综合分析', path: '/basicAnalysis' },
  //{ name: '上线分析', path: '/lineAnalysis' },
  { name: '关注学生', path: '/concernedStudent' },
  { name: '打包下载', path: '/download' },
  //{ name: "试卷评讲", path: '/review' },
  { name: "定制化报表", path: '/exportBook' },
]

export const smallQsNavList = [
  { name: '学生小题分', path: '/student' },
  { name: '选择题作答', path: '/choice' },
  { name: '题目得分明细', path: '/detail' },
  { name: '小题平均分', path: '/average' },
  { name: '试卷分析', path: '/qsDetail' },
]

export const sectionNavList = [
  { name: '分数段', path: '/score' },
  { name: '名次段', path: '/rank' },
  { name: '名次构成', path: '/from' },
]

export const levelNavList = [
  { name: '学生等级分', path: '/student' },
  //{ name: '班级等级分', path: '/unit' },
]

export const lineNavList = [
  { name: '物理类上线率分析', path: '/type1' },
  { name: '历史类上线率分析', path: '/type2' },
  { name: '划线概况', path: '/overview' },
]

export const concernNavList = [
  { name: '学优生', path: '/excellent' },
  { name: '临界生', path: '/critical' },
  // { name: '划线概况', path: '/overview' },
]

export const highExamSubject = {
  normal: [
    { id: 1, name: "语文" },
    { id: 2, name: "数学" },
  ],
  // 语言3选1
  language: [
    { id: 3, name: '英语' },
    { id: 70, name: '日语' },
    // { id: 79, name: '其他' },
  ],
  // 类型2选1
  type: [
    { id: 4, name: '物理' },
    { id: 8, name: '历史' },
  ],
  // 科目4选2
  courses: [
    { id: 5, name: '化学' },
    { id: 6, name: '生物' },
    { id: 7, name: '政治' },
    { id: 9, name: '地理' },
  ]
}

export const courseType = [
  { value: 0, label: '主三科' },
  { value: 1, label: '物理类' },
  { value: 2, label: '历史类' },
  { value: 3, label: '全部科' },
  { value: 4, label: '不显示' },
];

export const rankType = [
  // { id: 'joint_rank', name: '联考排名', width: '80px' },
  // { id: 'city_rank', name: '市排名', width: '80px' },
  { id: 'school_rank', name: '校排名', width: '80px' },
  { id: 'class_rank', name: '班排名', width: '80px' },
]

export const COMBINE_SUBJECT_LIST = [
  { id: 'subject_3001', name: '主三科', prop: 'subject_3001' },
  { id: 'subject_3101', name: '主三科+物理', prop: 'subject_3101' },
  { id: 'subject_3102', name: '主三科+历史', prop: 'subject_3102' },
]

export const scoreColumnList = [
  { label: '姓名', prop: 'student_name', width: '100px' },
  { label: '考号', prop: 'exam_no', width: '120px' },
  { label: '班级', prop: 'class_name', width: '120px' },
]

export const smallQsStudent = [
  { label: '序号', prop: 'index', width: '70px' },
  { label: '姓名', prop: 'student_name', width: '100px' },
  { label: '考号', prop: 'exam_no', width: '120px' },
  { label: '班级', prop: 'class_name', width: '120px' },
  { label: '总分', prop: 'total_score', width: '90px' },
  { label: '客观题', prop: 'objective_score', width: '90px' },
  { label: '主观题', prop: 'subjective_score', width: '90px' },
  { label: '学校排名', prop: 'school_rank', width: '90px' },
  { label: '班级排名', prop: 'class_rank', width: '90px' },
]

export const choiceStatColumn = [
  { label: '序号', prop: 'index', width: '70px' },
  { label: '姓名', prop: 'student_name', width: '100px' },
  { label: '考号', prop: 'exam_no', width: '120px' },
  { label: '班级', prop: 'class_name', width: '120px' },
  { label: '选择题总分', prop: 'full_score', width: '90px' },
  { label: '选择题得分', prop: 'score', width: '90px' },
  { label: '得分率', prop: 'score_ratio', width: '90px' },
]

export const blockAvgScore = [
  { label: '班级', prop: 'name', width: '120px' },
  { label: '类目', prop: 'type', width: '90px' },
  { label: '任课老师', prop: 'teacher_name', width: '110px' },
  { label: '统计人数', prop: 'actual_count', width: '90px' },
  { label: '缺考人数', prop: 'missing_count', width: '90px' },
  { label: '卷面分', prop: 'full_score', width: '90px' },
  { label: '平均分', prop: 'avg_score', width: '90px' },
  { label: '最高分', prop: 'max_score', width: '90px' },
  { label: '最低分', prop: 'min_score', width: '90px' },
  { label: '高分率', prop: 'high_ratio', width: '90px' },
  { label: '优秀率', prop: 'excellent_ratio', width: '90px' },
  { label: '良好率', prop: 'good_ratio', width: '90px' },
  { label: '及格率', prop: 'pass_ratio', width: '90px' },
  { label: '低分率', prop: 'low_ratio', width: '90px' },
  { label: '排名', prop: 'rank', width: '90px' },
  { label: '得分率', prop: 'score_ratio', width: '90px' },
  { label: '超均率', prop: 'over_avg_ratio', width: '90px' },
  { label: '标准差', prop: 'std_score', width: '110px' },
]

export const paperStat = [
  { label: '年级', prop: 'grade_name', width: '100px' },
  { label: '总人数', prop: 'total_count', width: '100px' },
  { label: '总分', prop: 'full_score', width: '100px' },
  { label: '最高分', prop: 'max_score', width: '100px' },
  { label: '最低分', prop: 'min_score', width: '100px' },
  { label: '平均分', prop: 'avg_score', width: '100px' },
  { label: '难度', prop: 'difficulty', width: '100px', tip: '难度：表示试题的难易程度。介于0-1之间，值越小表示试题越简单。' },
  //{ label: '区分度', prop: 'distinction', width: '100px', tip: '区分度：表示试题对考生能力的区分程度，值越大表明试题区分不同能力考生的效果越好，试题采用的价值也越大。取值范围介于-1.00至+1.00之间，一般情况下，区分度应为正值，称作积极区分，值越大则区分度越好。', },
  {
    label: '标准差', prop: 'std_score', width: '100px',
    tip: '标准差：是一组数值自平均值分散开来的程度的一种测量观念。一个较大的标准差，代表大部分的数值和其平均值之间差异较大；一个较小的标准差，代表这些数值较接近平均值。',
  },
  {
    label: '优秀率', prop: 'high_rate', width: '100px',
    tip: '得分率说明：优秀（90%-100%）',
  },
  {
    label: '良好率', prop: 'good_rate', width: '100px',
    tip: '得分率说明：良好（80%-100%）',
  },
  {
    label: '及格率', prop: 'pass_rate', width: '100px',
    tip: '得分率说明：及格（60%-100%）'
  },
  {
    label: '低分率', prop: 'low_rate', width: '100px',
    tip: '得分率说明：低分（ 0%-30%）',
  },
]

export const blockScores = [
  { label: '题号', prop: 'block_num', width: '80px' },
  { label: '题型', prop: 'block_type_name', width: '80px' },
  { label: '包含题目', prop: 'block_name', width: '110px' },
  { label: '分值', prop: 'block_full_score', width: '80px' },
  { label: '作答人数', prop: 'answer_student_num', width: '100px' },
  { label: '平均分', prop: 'avg_score', width: '80px' },
  { label: '得分率', prop: 'score_ratio', width: '80px' },
  { label: '满分率', prop: 'full_score_ratio', width: '80px' },
  { label: '零分率', prop: 'zero_score_ratio', width: '80px' },
  {
    label: '难度', prop: '_difficulty_', width: '160px', children: [
      { label: '系数', prop: 'difficulty', width: '80px' },
      { label: '描述', prop: 'difficulty_desc', width: '80px' },
    ]
  },
  //{ label: '区分度', prop: 'distinction', width: '80px' },
  { label: '标准差', prop: 'std_score', width: '80px' },
]

export const initLevelValue = {
  // 按比例
  'ratio': [5, 20, 45, 70, 85, 100],
  // 按分数
  'grade': [0.9, 0.85, 0.75, 0.65, 0.6, 0.5],
}

export const initPattern = '17:100-83,33:82-71,33:70-59,15:58-41,2:40-30';

export const studentLevelColumnList = [
  { label: '姓名', prop: 'student_name', width: '100px', },
  { label: '学校', prop: 'school_name', width: '200px', },
  { label: '班级', prop: 'class_name', width: '120px', },
]


// 学生等级分的科目列表
export const studentLevelSubjectList = [
  { id: 1004, name: '物理' },
  { id: 1005, name: '化学' },
  { id: 1006, name: '生物' },
  { id: 1007, name: '政治' },
  { id: 1008, name: '历史' },
  { id: 1009, name: '地理' },
  { id: 3100, name: '六科总分)' },
  { id: 3103, name: '六科总分(物理)' },
  { id: 3104, name: '六科总分(历史)' },
]

export const initLineSetting = {
  1: '130,100,70',
  2: '130,100,70',
  3103: '400,350,300',
}

export const lineAnalysisColumnList = [
  { label: '单位', prop: 'name', width: '120px' },
  { label: '总人数', prop: 'student_num', width: '120px' },
  {
    label: '重点线', prop: 'one', children: [
      { label: '人数', prop: 'one_num', width: '120px' },
      { label: '上线率', prop: 'one_ratio', width: '120px' },
    ]
  },
  {
    label: '本科线', prop: 'two', children: [
      { label: '人数', prop: 'two_num', width: '120px' },
      { label: '上线率', prop: 'two_ratio', width: '120px' },
    ]
  },
  {
    label: '专科线', prop: 'three', children: [
      { label: '人数', prop: 'three_num', width: '120px' },
      { label: '上线率', prop: 'three_ratio', width: '120px' },
    ]
  },
  {
    label: '未上线', prop: 'not_online', children: [
      { label: '人数', prop: 'not_online_num', width: '120px' },
      { label: '未上线率', prop: 'not_online_ratio', width: '120px' },
    ]
  },
]

export const excellenceStuColumnList = [
  { label: '序号', prop: 'index', width: '100px' },
  { label: '学校', prop: 'school_name', width: '200px' },
  { label: '班级', prop: 'class_name', width: '120px' },
  { label: '姓名', prop: 'student_name', width: '120px' },
  { label: '考号', prop: 'exam_no', width: '120px' },
  { label: '排名', prop: 'school_rank', width: '100px' },
  { label: '分数', prop: 'score', width: '100px' },
]

export const criticalStuColumnList = [
  { label: '序号', prop: 'index', width: '100px' },
  { label: '学校', prop: 'school_name', width: '200px' },
  { label: '班级', prop: 'class_name', width: '120px' },
  { label: '姓名', prop: 'student_name', width: '120px' },
  { label: '考号', prop: 'exam_no', width: '120px' },
  { label: '校排名', prop: 'school_rank', width: '100px' },
  { label: '班排名', prop: 'class_rank', width: '100px' },
  { label: '分数', prop: 'score', width: '100px' },
  { label: '达标线', prop: 'base', width: '100px' },
  { label: '临界幅度', prop: 'diff', width: '100px' },
]

export const analysisOverviewColumnList = [
  { label: '科目', prop: 'subject', width: '120px' },
  {
    label: '特控线', prop: 'one', children: [
      { label: '分数', prop: 'one_score', width: '120px' },
      { label: '人数', prop: 'one_num', width: '120px' },
    ]
  },
  {
    label: '本科线', prop: 'two', children: [
      { label: '分数', prop: 'two_score', width: '120px' },
      { label: '人数', prop: 'two_num', width: '120px' },
    ]
  },
  {
    label: '专科线', prop: 'three', children: [
      { label: '分数', prop: 'three_score', width: '120px' },
      { label: '人数', prop: 'three_num', width: '120px' },
    ]
  },
]

// 不能看学生成绩单的角色列表
export const CANNOT_SEE_ROLE_LIST = ['备课组长', '科任老师'];

// 可以看到 '定制化报表' 的学校
export const GRADE_BOOK_SCHOOL_ID_LIST = [1000699, 1000681, 1000685];

// 可以看到 '定制化报表' 的角色
export const GRADE_BOOK_ROLE_LIST = ['校务管理员', '校长', '副校长', '教务主任', '年级组长', '年级主任'];
